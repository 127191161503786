import React from 'react'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'

import styles from './Modal.module.scss'

type ModalProps = {
  open: boolean
  handleClose: any
  name: string
  onSuccess?: boolean
  children: any
  height?: any
  width?: any
  noClose?: boolean
  maxHeight?: any
  minWidth?: number
  minHeight?: number
}

const TotemModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  name,
  onSuccess,
  children,
  height,
  width,
  noClose,
  maxHeight,
  minWidth,
  minHeight
}: ModalProps) => {
  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width,
    height,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingTop: 2,
    paddingLeft: 4,
    paddingRight: 4,
    paddingBottom: 2,
    maxHeight,
    minWidth,
    minHeight,
    transition: 'all ease-in-out .2s',
    borderRadius: '4px'
  }
  return (
    <Modal className={styles.modal} open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className={`${styles.header} ${onSuccess ? styles.success : ''}`}>
          <p>{name}</p>
          {!noClose && (
            <CloseIcon className={styles.closeIcon} onClick={handleClose} />
          )}
        </div>
        <div className={styles.content}>{children}</div>
      </Box>
    </Modal>
  )
}

TotemModal.defaultProps = {
  onSuccess: false,
  height: 700,
  width: 700,
  noClose: false,
  maxHeight: 700,
  minWidth: 100
}

export default TotemModal
