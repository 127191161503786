import React from 'react'

import Navegando from '../../../assets/images/Navegando.svg'
import styles from './loader.module.scss'

const Loader: React.FC = () => {
  return (
    <div className="loadingQueueMessage mt-5 mb-2">
      <div className={styles.rotating}>
        <img
          alt="Navegando"
          src={Navegando}
          className="jet mb-2"
          style={{ height: 32, width: 32 }}
        />
      </div>
      <h5 className="mb-5">Carregando dados, aguarde...</h5>
    </div>
  )
}

export default Loader
