import env from 'react-dotenv'
import { serviceNts } from '../ServiceNts.service'

const { API_APP } = env

const employeeByCredential = async (
  credential: string
): Promise<any | Error> => {
  try {
    const dinamicUrl = `${API_APP}/employee/credential/${credential}`
    const locator = await serviceNts.get(dinamicUrl)
    return Promise.resolve(locator)
  } catch (error) {
    return Promise.reject(
      new Error(
        (error as { message: string }).message || 'Erro ao consultar o registro'
      )
    )
  }
}

export const employeeService = {
  employeeByCredential
}
