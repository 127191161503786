import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import './app/scss/App.scss'
import theme from './app/theme/theme'
import Routes from './app/routes'

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Routes />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
