import React, { useState } from 'react'

import { Button, TextField } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import { useSnackbar } from 'notistack'
import { cpf } from 'cpf-cnpj-validator'
import styles from './CheckInConfirmationModal.module.scss'
import TotemModal from '../../../../components/modal/Modal'
import { NumberFormatDocument } from '../../../../components/number-format/NumberFormat'
import { movementService } from '../../../../services/ms-nts/Movement.service'
import { isEmpty } from '../../../../utils/validators'

type ModalProps = {
  open: boolean
  handleClose: any
  height: any
  onLogout?: any
  onCancelReservation?: any
  onCancel?: any
  onConfirm?: any
  onCheckIn?: any
  width: any
  type?: string
  reservation: string
  owners: any[]
  conductor: string
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#3366FF'
    },
    secondary: {
      main: '#4E5F78'
    },
    mode: 'light'
  },

  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  }
})

const CheckInConfirmationModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  height,
  onLogout,
  onCancelReservation,
  onCancel,
  onConfirm,
  onCheckIn,
  width,
  type,
  reservation,
  owners,
  conductor
}: ModalProps) => {
  const [success, setSuccess] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const [canceledReservation, setCanceledReservation] = useState(false)
  const [departureRequested, setDepartureRequested] = useState(false)
  const [ownerDocument, setOwnerDocument] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const cancel = () => {
    onCancel()
    setOwnerDocument('')
  }

  const getTitle = () => {
    switch (type) {
      case 'confirm':
        return 'Confirmar Check-In'
      case 'requestDeparture':
        return 'Solicitar descida'
      case 'cancelCheckin':
        return 'Cancelar Check-in'
      case 'cancel':
        return 'Cancelar agendamento'
      case 'logout':
        return 'Sair'
      default:
        return ''
    }
  }

  const getConfirmation = () => {
    switch (type) {
      case 'confirm':
        return `Para confirmar o Check-in do localizador ${reservation} digite o CPF do responsável:`
      case 'requestDeparture':
        return `Para solicitar a descida do localizador ${reservation} digite o CPF do responsável:`
      case 'cancelCheckin':
        return `Para cancelar o Check-in do localizador ${reservation} digite o CPF do responsável:`
      case 'cancel':
        return `Para cancelar o agendamento do localizador ${reservation} digite o CPF do responsável:`
      case 'logout':
        return `${
          reservation === '4RE47A'
            ? 'Deseja sair e cancelar o processo de solicitar descida?'
            : 'Deseja sair e cancelar o processo de check-in?'
        }`
      default:
        return ''
    }
  }

  const getTextButton = () => {
    switch (type) {
      case 'confirm':
        return 'Confirmar Check-In'
      case 'requestDeparture':
        return 'Solicitar descida'
      case 'cancelCheckin':
        return 'Cancelar Check-in'
      case 'cancel':
        return 'Cancelar agendamento'
      case 'logout':
        return 'Sair'
      default:
        return ''
    }
  }

  const handleConfirmation = () => {
    switch (type) {
      case 'logout':
        onLogout()
        break
      case 'cancel':
        cancelReservation()
        break
      case 'confirm':
        confirm()
        break
      case 'requestDeparture':
        requestDeparture()
        break
      case 'cancelCheckin':
        cancelCheckin()
        break
      default:
        break
    }
  }

  const requestDeparture = async () => {
    setConfirming(true)
    const exists = owners.filter((item: any) => {
      return item.owner.registration === ownerDocument
    })
    if (exists.length > 0) {
      const movement = await movementService.getByLocator(reservation)
      if (!isEmpty(movement)) {
        await movementService.updateMove('', reservation, 1, 1)
      }
      setDepartureRequested(true)
      setSuccess(true)
      setConfirming(false)
    } else {
      enqueueSnackbar(
        `CPF não corresponde com o localizador ${reservation}. Confira os dados e tente novamente.`,
        {
          variant: 'error'
        }
      )
      setConfirming(false)
    }
  }

  const cancelCheckin = async () => {
    setConfirming(true)
    const exists = owners.filter((item: any) => {
      return item.owner.registration === ownerDocument
    })
    if (exists.length > 0) {
      const movement = await movementService.getByLocator(reservation)
      if (!isEmpty(movement)) {
        await movementService.updateMove('', reservation, 0, 1)
        await movementService.updateCheckIn(
          reservation,
          'canceled',
          ownerDocument
        )
      }
      // setDepartureRequested(true)
      setSuccess(true)
      setConfirming(false)
    } else {
      enqueueSnackbar(
        `CPF não corresponde com o localizador ${reservation}. Confira os dados e tente novamente.`,
        {
          variant: 'error'
        }
      )
      setConfirming(false)
    }
  }

  const confirm = async () => {
    if (type === 'cancel') {
      onConfirm()
    }
    setConfirming(true)

    const exists = owners.filter((item: any) => {
      return item.owner.registration === ownerDocument
    })
    if (exists.length > 0) {
      onCheckIn(ownerDocument)
      setSuccess(true)
      setConfirming(false)
    } else {
      enqueueSnackbar(
        `CPF não corresponde com o localizador ${reservation}. Confira os dados e tente novamente.`,
        {
          variant: 'error'
        }
      )
      setConfirming(false)
    }
  }

  const cancelReservation = async () => {
    setConfirming(true)

    const exists = owners.filter((item: any) => {
      return item.owner.registration === ownerDocument
    })
    if (exists.length > 0) {
      onCancelReservation(ownerDocument)
      setCanceledReservation(true)
      setSuccess(true)
      setConfirming(false)
    } else {
      enqueueSnackbar(
        `CPF não corresponde com o localizador ${reservation}. Confira os dados e tente novamente.`,
        {
          variant: 'error'
        }
      )
      setConfirming(false)
    }
  }

  const handleKeypress = (e: any) => {
    if (e.charCode === 13) {
      handleConfirmation()
    }
  }

  const handleClickDepartureRequested = (e: any) => {
    e.preventDefault()
    movementService.updateMove('', reservation, 1, 1).then(() => {
      setDepartureRequested(true)
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <TotemModal
        open={open}
        name={
          success && departureRequested
            ? 'Descida solicitada'
            : success && !canceledReservation
            ? 'Check-in realizado'
            : success && canceledReservation
            ? 'Reserva cancelada'
            : getTitle()
        }
        handleClose={handleClose}
        noClose
        width={success ? 500 : width}
        onSuccess={success}
        height={height}
      >
        {success ? (
          <div className="d-flex align-items-center flex-column">
            <h5 className="mt-2 gray-text">{reservation}</h5>
            <h5 className="gray-text">{conductor}</h5>
            <h6 className="text-center mt-3">
              {departureRequested && success
                ? 'Descida solicitada com sucesso!'
                : canceledReservation && success
                ? `Reserva cancelada com sucesso. Se desejar fazer uma nova reserva, acesse o botão "Agendamento" na tela inicial.`
                : 'Check-in realizado com sucesso! Gostaria de descer a embarcação agora?'}
            </h6>
            {departureRequested && success && (
              <h6>Acompanhe a fila de movimentação.</h6>
            )}
            {departureRequested && success && <h6>Navegue com Segurança!</h6>}

            <div className="justify-content-center mt-2 d-flex flex-column">
              {!departureRequested && !canceledReservation && (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={e => handleClickDepartureRequested(e)}
                    className={`${styles.submit} mb-2`}
                    size="large"
                  >
                    Descer agora
                  </Button>
                  <Button
                    color="inherit"
                    variant="outlined"
                    onClick={() => onConfirm()}
                    className={`${styles.submit} mb-2 mt-3`}
                    size="large"
                  >
                    Descer depois
                  </Button>
                  <p className="text-center">
                    Para solicitar a descida posteriormente, acesse novamente
                    com seu localizador na tela inicial.
                  </p>
                </>
              )}
              {type === 'cancel' && (
                <div className="d-flex flex-column align-items-center mb-2">
                  <h6 className="gray-text bolder">Data | Hora</h6>
                  <h5>
                    {new Date().toLocaleDateString()} -{' '}
                    {new Date().toLocaleTimeString()}
                  </h5>
                </div>
              )}
            </div>
            {type === 'cancel' && (
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => onConfirm()}
                className={`${styles.submit} mb-2 mt-1`}
                size="large"
              >
                FECHAR
              </Button>
            )}
            {departureRequested && (
              <>
                <h6 className="gray-text bolder">Data | Hora</h6>
                <h5 className="mb-3">
                  {new Date().toLocaleDateString()} -{' '}
                  {new Date().toLocaleTimeString()}
                </h5>
                <Button
                  color="inherit"
                  variant="outlined"
                  onClick={() => onConfirm()}
                  className={`${styles.submit} mb-2 mt-1`}
                  size="large"
                >
                  FECHAR
                </Button>
              </>
            )}
            {type !== 'cancel' && !departureRequested && (
              <>
                <h6 className="gray-text mt-3 bolder">Data | Hora</h6>
                <h5>
                  {new Date().toLocaleDateString()} -{' '}
                  {new Date().toLocaleTimeString()}
                </h5>
              </>
            )}
          </div>
        ) : (
          <div className="d-flex align-items-center flex-column">
            <h6 className="gray-text text-center">{getConfirmation()}</h6>
            {type !== 'logout' && (
              <TextField
                margin="normal"
                name="document"
                type="text"
                id="document"
                autoFocus
                variant="outlined"
                autoComplete="off"
                onKeyPress={handleKeypress}
                onChange={e => setOwnerDocument(e.target.value)}
                value={ownerDocument}
                error={
                  (ownerDocument.length === 11 &&
                    !cpf.isValid(ownerDocument)) ||
                  ownerDocument.length > 11
                }
                label="CPF"
                disabled={confirming}
                color="primary"
                className="mt-3 mb-0"
                sx={{ minWidth: 325, maxWidth: 325 }}
                helperText={
                  ownerDocument.length === 11 && !cpf.isValid(ownerDocument)
                    ? 'Digite um CPF válido'
                    : ''
                }
                InputProps={{
                  inputComponent: NumberFormatDocument as any
                }}
              />
            )}
            <div className="mt-4 justify-content-center d-flex">
              <Button
                variant="outlined"
                onClick={() => cancel()}
                color="secondary"
                className={`${styles.submit} me-4`}
                disabled={confirming}
                size="large"
              >
                Cancelar
              </Button>
              <Button
                disabled={
                  type !== 'logout' &&
                  (confirming ||
                    ownerDocument.length < 11 ||
                    ownerDocument.length > 11 ||
                    (ownerDocument.length === 11 &&
                      !cpf.isValid(ownerDocument)))
                }
                variant="outlined"
                color="primary"
                size="large"
                className={`${styles.submit}`}
                onClick={() => handleConfirmation()}
              >
                {!confirming ? getTextButton() : 'Aguarde...'}
              </Button>
            </div>
          </div>
        )}
      </TotemModal>
    </ThemeProvider>
  )
}

CheckInConfirmationModal.defaultProps = {
  onCancel: false,
  onCancelReservation: false,
  onConfirm: false,
  type: ''
}

export default CheckInConfirmationModal
