import React, { useEffect, useState } from 'react'

import { Button, TextField } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import { useSnackbar } from 'notistack'
import styles from './ReservationConfirmationModal.module.scss'
import TotemModal from '../../../../components/modal/Modal'
import { movementService } from '../../../../services/ms-nts'
import { removeFormatDate } from '../../../../utils/masks'
import {
  calcArrival,
  calcEstimated,
  toInt
} from '../../../../utils/auxiliary-functions'
import { employeeService } from '../../../../services/ms-nts/Employee.service'
import { isEmpty } from '../../../../utils/validators'

type ModalProps = {
  open: boolean
  handleClose: any
  height: any
  onLogout?: any
  onCancelReservation?: any
  onCancel?: any
  onConfirm?: any
  width: any
  type?: string
  reservation: string
  tempReservation?: any
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#3366FF'
    },
    secondary: {
      main: '#4E5F78'
    },
    mode: 'light'
  },

  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  }
})

const ReservationConfirmationModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  height,
  onLogout,
  onCancelReservation,
  onCancel,
  onConfirm,
  width,
  type,
  reservation,
  tempReservation
}: ModalProps) => {
  const [success, setSuccess] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const [canceledReservation, setCanceledReservation] = useState(false)
  const [credential, setCredential] = useState('')
  const [operator, setOperator] = useState({} as any)
  const [locator, setLocator] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const cancel = () => {
    onCancel()
    setCredential('')
  }

  const getTitle = () => {
    switch (type) {
      case 'confirmArrive':
        return 'Confirmar subida'
      case 'confirmDescent':
        return 'Confirmar descida'
      case 'confirmAscent':
        return 'Confirmar subida'
      case 'finishDescent':
        return 'Finalizar descida'
      case 'requestedAscent':
        return 'Solicitar Subida'
      case 'requestedDescent':
        return 'Solicitar descida'
      case 'finishAscent':
        return 'Finalizar Subida'
      case 'reject':
        return 'Rejeitar descida'
      case 'rejectArrive':
        return 'Rejeitar subida'
      case 'individual':
        return 'Confirmar saída pontual'
      case 'new':
        return 'Confirmar agendamento'
      case 'customer':
        return 'Confirmar agendamento'
      case 'cancel':
        return 'Cancelar agendamento'
      case 'logout':
        return 'Sair'
      default:
        return ''
    }
  }

  const getConfirmation = () => {
    switch (type) {
      case 'confirmArrive':
        return `Para confirmar a navegaçãoo do localizador ${reservation} digite ou aproxime sua credencial de operador:`
      case 'confirmDescent':
        return `Para confirmar a descida do localizador ${reservation} digite ou aproxime sua credencial de operador:`
      case 'confirmAscent':
        return `Para confirmar a subida do localizador ${reservation} digite ou aproxime sua credencial de operador:`
      case 'requestedAscent':
        return `Para solicitar a descida do localizador ${reservation} digite ou aproxime sua credencial de operador:`
      case 'requestedDescent':
        return `Para solicitar a subida do localizador ${reservation} digite ou aproxime sua credencial de operador:`
      case 'finishDescent':
        return `Para finalizar a descida do localizador ${reservation} digite ou aproxime sua credencial de operador:`
      case 'finishAscent':
        return `Para finalizar a subida do localizador ${reservation} digite ou aproxime sua credencial de operador:`
      case 'reject':
        return `Para rejeitar a descida do localizador ${reservation} digite ou aproxime sua credencial de operador:`
      case 'individual':
        return `Para confirmar a saída pontual digite ou aproxime sua credencial de operador:`
      case 'customer':
        return `Confirma o agendamento para ${new Date(
          tempReservation.date
        ).toLocaleDateString()} - ${tempReservation.departure}?`
      case 'new':
        return `Para confirmar o agendamento digite ou aproxime sua credencial de operador:`
      case 'cancel':
        return `Para cancelar o agendamento do localizador ${reservation} digite ou aproxime sua credencial de operador:`
      case 'logout':
        return 'Deseja sair e cancelar o processo de check-in?'
      default:
        return ''
    }
  }

  const getTextButton = () => {
    switch (type) {
      case 'confirmArrive':
        return 'Confirmar subida'
      case 'confirmDescent':
        return 'Confirmar descida'
      case 'confirmAscent':
        return 'Confirmar subida'
      case 'requestedAscent':
        return 'Solicitar Subida'
      case 'requestedDescent':
        return 'Solicitar descida'
      case 'finishDescent':
        return 'Finalizar descida'
      case 'finishAscent':
        return 'Finalizar Subida'
      case 'reject':
        return 'Rejeitar descida'
      case 'rejectArrive':
        return 'Rejeitar subida'
      case 'individual':
        return 'Confirmar saída pontual'
      case 'new':
        return 'Confirmar agendamento'
      case 'customer':
        return 'Confirmar agendamento'
      case 'cancel':
        return 'Cancelar agendamento'
      case 'logout':
        return 'Sair'
      default:
        return ''
    }
  }

  const handleConfirmation = () => {
    switch (type) {
      case 'logout':
        onLogout()
        break
      case 'cancel':
        cancelReservation()
        break
      case 'confirmArrive':
        confirm()
        break
      case 'confirmDescent':
        confirm()
        break
      case 'confirmAscent':
        confirm()
        break
      case 'requestedAscent':
        confirm()
        break
      case 'requestedDescent':
        confirm()
        break
      case 'finishDescent':
        confirm()
        break
      case 'finishAscent':
        confirm()
        break
      case 'reject':
        confirm()
        break
      case 'rejectArrive':
        confirm()
        break
      case 'customer':
        confirm()
        break
      case 'individual':
        confirm()
        break
      case 'new':
        confirm()
        break
      default:
        break
    }
  }
  /*
   *         0: neutral
   *         1: desce requested
   *         2: sobe requested
   *         3: desce confirmed
   *         4: sobe confirmed
   *         5: desce finished
   *         6: sobe finished
   */

  const confirm = async () => {
    setConfirming(true)

    if (type === 'customer') {
      const dataCrews = [
        {
          kind: '1',
          conductorId: tempReservation.ownerId,
          vesselId: tempReservation.vesselId
        }
      ]

      const estimated = calcEstimated(
        `${tempReservation.date.toLocaleDateString()} ${
          tempReservation.departure
        }:00`,
        tempReservation.arrives
      )

      const arrival = calcArrival(
        `${tempReservation.date.toLocaleDateString()} ${
          tempReservation.departure
        }:00`,
        estimated
      )

      const reserve = {
        dateMovement: removeFormatDate(
          tempReservation.date.toLocaleDateString()
        ),
        partnerId: tempReservation.partnerId,
        vesselId: tempReservation.vesselId,
        departure: removeFormatDate(
          `${tempReservation.date.toLocaleDateString()} ${
            tempReservation.departure
          }:00`
        ),
        estimated,
        status: '1',
        arrival: removeFormatDate(arrival),
        quantityPeople: 1 + toInt(tempReservation.passengers),
        navigationPlan: tempReservation.navigationPlan,
        sailorRequested: tempReservation.sailorRequested,
        crews: dataCrews
      }
      movementService
        .createReserve(reserve)
        .then((result: any) => {
          setLocator(result)
          setSuccess(true)
          setConfirming(false)
        })
        .catch((error: any) => {
          enqueueSnackbar(error.message, {
            variant: 'error'
          })
        })
      setConfirming(false)
    } else {
      employeeService
        .employeeByCredential(credential)
        .then(async (employee: any) => {
          if (employee) {
            setOperator(employee)

            if (type === 'confirmDescent') {
              if (reservation !== '') {
                const movement = await movementService.getByLocator(reservation)
                if (!isEmpty(movement)) {
                  await movementService.updateMove(
                    employee.credential,
                    reservation,
                    3,
                    8
                  )
                }
                setSuccess(true)
                setConfirming(false)
              }
            } else if (type === 'confirmAscent') {
              if (reservation !== '') {
                const movement = await movementService.getByLocator(reservation)
                if (!isEmpty(movement)) {
                  await movementService.updateMove(
                    employee.credential,
                    reservation,
                    4,
                    7
                  )
                }
                setSuccess(true)
                setConfirming(false)
              }
            } else if (type === 'finishDescent') {
              if (reservation !== '') {
                const movement = await movementService.getByLocator(reservation)
                if (!isEmpty(movement)) {
                  await movementService.updateMove(
                    employee.credential,
                    reservation,
                    5,
                    5
                  )
                }
                setSuccess(true)
                setConfirming(false)
              }
            } else if (type === 'requestedAscent') {
              if (reservation !== '') {
                const movement = await movementService.getByLocator(reservation)
                if (!isEmpty(movement)) {
                  await movementService.updateMove(
                    employee.credential,
                    reservation,
                    2,
                    5
                  )
                }
                setSuccess(true)
                setConfirming(false)
              }
            } else if (type === 'requestedDescent') {
              if (reservation !== '') {
                const movement = await movementService.getByLocator(reservation)
                if (!isEmpty(movement)) {
                  await movementService.updateMove(
                    employee.credential,
                    reservation,
                    1,
                    1
                  )
                }
                setSuccess(true)
                setConfirming(false)
              }
            } else if (type === 'finishAscent') {
              if (reservation !== '') {
                const movement = await movementService.getByLocator(reservation)
                if (!isEmpty(movement)) {
                  await movementService.updateMove(
                    employee.credential,
                    reservation,
                    6,
                    1
                  )
                  await movementService.updateCheckIn(
                    reservation,
                    'finished',
                    movement.registratioCheckIn
                  )
                }
                setSuccess(true)
                setConfirming(false)
              }
            } else if (type !== 'new' && type !== 'individual') {
              if (reservation !== '') {
                const movement = await movementService.getByLocator(reservation)
                if (!isEmpty(movement)) {
                  await movementService.updateMove(
                    employee.credential,
                    reservation,
                    1,
                    6
                  )
                }
                setSuccess(true)
                setConfirming(false)
              }
            } else {
              const dataCrews = [
                {
                  kind: '1',
                  conductorId: tempReservation.ownerId,
                  vesselId: tempReservation.vesselId
                }
              ]

              const estimated = calcEstimated(
                `${tempReservation.date.toLocaleDateString()} ${
                  tempReservation.departure
                }:00`,
                tempReservation.arrives
              )

              const arrival = calcArrival(
                `${tempReservation.date.toLocaleDateString()} ${
                  tempReservation.departure
                }:00`,
                estimated
              )

              const reserve = {
                dateMovement: removeFormatDate(
                  tempReservation.date.toLocaleDateString()
                ),
                partnerId: tempReservation.partnerId,
                vesselId: tempReservation.vesselId,
                departure: removeFormatDate(
                  `${tempReservation.date.toLocaleDateString()} ${
                    tempReservation.departure
                  }:00`
                ),
                estimated,
                status: '1',
                arrival: removeFormatDate(arrival),
                quantityPeople: 1 + toInt(tempReservation.passengers),
                navigationPlan: tempReservation.navigationPlan,
                sailorRequested: tempReservation.sailorRequested,
                crews: dataCrews
              }
              movementService
                .createReserve(reserve)
                .then((result: any) => {
                  setLocator(result)
                  setSuccess(true)
                  setConfirming(false)
                })
                .catch((error: any) => {
                  enqueueSnackbar(error.message, {
                    variant: 'error'
                  })
                })
              setConfirming(false)
            }
            // setConfirming(false)
          } else {
            enqueueSnackbar(
              `Credencial não localizada. Confira os dados e tente novamente.`,
              {
                variant: 'error'
              }
            )
            setConfirming(false)
          }
        })
        .catch((error: any) => {
          enqueueSnackbar(`${error.message}`, {
            variant: 'error'
          })
          setConfirming(false)
        })
    }
  }

  const cancelReservation = async () => {
    setConfirming(true)

    if (credential === tempReservation.ownerRegistration) {
      setCanceledReservation(true)
      setSuccess(true)
      setConfirming(false)
      onCancelReservation()
    } else {
      enqueueSnackbar(
        `CPF não corresponde com o localizador ${reservation}. Confira os dados e tente novamente.`,
        {
          variant: 'error'
        }
      )
      setConfirming(false)
    }
  }

  useEffect(() => {
    if (type === 'reject' || type === 'rejectArrive') setSuccess(true)
  }, [type])

  const handleKeypress = (e: any) => {
    if (e.charCode === 13) {
      handleConfirmation()
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <TotemModal
        open={open}
        name={
          (success && type === 'new') || (success && type === 'customer')
            ? 'Agendamento confirmado'
            : success && type === 'individual'
            ? 'Saída pontual confirmada'
            : success && type === 'reject'
            ? 'Descida rejeitada'
            : success && type === 'rejectArrive'
            ? 'Subida rejeitada'
            : success && type === 'confirmArrive'
            ? 'Subida confirmada'
            : success && type === 'finishDescent'
            ? 'Descida finalizada'
            : success && type === 'finishAscent'
            ? 'Subida finalizada'
            : success && type === 'confirmDescent'
            ? 'Descida confirmada'
            : success && type === 'confirmAscent'
            ? 'Subida confirmada'
            : success && !canceledReservation
            ? 'Descida confirmada'
            : success && canceledReservation
            ? 'Reserva cancelada'
            : getTitle()
        }
        handleClose={handleClose}
        noClose
        width={
          type === 'individual' || type === 'new' || type === 'customer'
            ? width + 50
            : success
            ? 500
            : width
        }
        onSuccess={success}
        height={height}
      >
        {success ? (
          <div className="d-flex align-items-center flex-column">
            {type !== 'new' && type !== 'individual' && type !== 'customer' && (
              <h5 className="mt-2 gray-text">{reservation}</h5>
            )}
            <h5 className="gray-text">
              {type === 'reject' || type === 'rejectArrive'
                ? 'NOVO LEMBRETE EM 3 MINUTOS'
                : tempReservation.ownerName}
            </h5>
            <h6 className="text-center mt-3">
              {(type === 'new' && success) || (type === 'customer' && success)
                ? `Agendamento programado para`
                : type === 'individual' && success
                ? 'Saída pontual confirmada com sucesso!'
                : type === 'confirmArrive' && success
                ? 'Subida confirmada com sucesso!'
                : type === 'finishDescent' && success
                ? 'Descida finalizada com sucesso!'
                : type === 'finishAscent' && success
                ? 'Subida finalizada com sucesso!'
                : type === 'confirmDescent' && success
                ? 'Descida confirmada com sucesso!'
                : type === 'confirmAscent' && success
                ? 'Subida confirmada com sucesso!'
                : type === 'reject' && success
                ? ''
                : type === 'rejectArrive' && success
                ? ''
                : canceledReservation && success
                ? `Reserva cancelada com sucesso. Se desejar fazer uma nova reserva, acesse o botão "Agendamento" na tela inicial. `
                : 'Descida confirmada com sucesso!'}
            </h6>
            {(type === 'individual' || type === 'confirmDescent') && (
              <>
                <h5 className="mt-2" style={{ color: 'red' }}>
                  ATENÇÃO
                </h5>
                <h6 className="text-center mt-1">
                  Movimente a embarcação até a rampa com cuidado!
                </h6>
              </>
            )}
            {type === 'confirmArrive' && (
              <>
                <h5 className="mt-2" style={{ color: 'red' }}>
                  ATENÇÃO
                </h5>
                <h6 className="text-center mt-1">
                  Movimente a embarcação até a rampa com cuidado!
                </h6>
              </>
            )}
            {(type === 'new' || type === 'customer') && (
              <>
                <h5>{`${new Date(tempReservation.date).toLocaleDateString()} -
                      ${tempReservation.departure}`}</h5>
                <div className="mt-2">
                  <h6>Localizador</h6>
                  <h5>{locator || reservation}</h5>
                </div>
              </>
            )}

            {type !== 'customer' &&
              type !== 'reject' &&
              type !== 'rejectArrive' && (
                <>
                  <h6 className="mt-2">Operador</h6>
                  <h5>{operator.name}</h5>
                </>
              )}
            {type !== 'reject' && type !== 'rejectArrive' && (
              <>
                <h6 className="gray-text bolder mt-2">Data | Hora</h6>
                <h5>
                  {new Date().toLocaleDateString()} -{' '}
                  {new Date().toLocaleTimeString()}
                </h5>
              </>
            )}
            <div
              className={`justify-content-center d-flex ${
                type === 'reject' || type === 'rejectArrive' ? 'mt-0' : 'mt-4'
              }`}
            >
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => onConfirm()}
                className={`${styles.submit} mb-2`}
                size="large"
              >
                Fechar
              </Button>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center flex-column">
            <h6 className="gray-text text-center">{getConfirmation()}</h6>
            {type !== 'logout' && type !== 'customer' && (
              <TextField
                margin="normal"
                name="credential"
                type="password"
                id="credential"
                autoFocus
                variant="outlined"
                autoComplete="off"
                onChange={e => setCredential(e.target.value)}
                value={credential}
                onKeyPress={handleKeypress}
                label="Credencial"
                disabled={confirming}
                color="primary"
                className="mt-3 mb-0"
                sx={{ minWidth: 325, maxWidth: 325 }}
              />
            )}
            <div className="mt-4 justify-content-center d-flex">
              <Button
                variant="outlined"
                onClick={() => cancel()}
                color="secondary"
                className={`${styles.submit} me-4`}
                disabled={confirming}
                size="large"
              >
                Cancelar
              </Button>
              {type !== 'customer' &&
                type !== 'reject' &&
                type !== 'rejectArrive' && (
                  <Button
                    disabled={
                      type !== 'logout' && (confirming || credential === '')
                    }
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={`${styles.submit}`}
                    onClick={() => handleConfirmation()}
                  >
                    {!confirming ? getTextButton() : 'Aguarde...'}
                  </Button>
                )}
              {(type === 'customer' ||
                type === 'reject' ||
                type === 'rejectArrive') && (
                <Button
                  disabled={confirming}
                  variant="outlined"
                  color="primary"
                  size="large"
                  className={`${styles.submit}`}
                  onClick={() => handleConfirmation()}
                >
                  {!confirming ? getTextButton() : 'Aguarde...'}
                </Button>
              )}
            </div>
          </div>
        )}
      </TotemModal>
    </ThemeProvider>
  )
}

ReservationConfirmationModal.defaultProps = {
  onCancel: false,
  onCancelReservation: false,
  onConfirm: false,
  type: ''
}

export default ReservationConfirmationModal
