import React from 'react'
import {
  Route,
  BrowserRouter as Router,
  Routes as Switch
} from 'react-router-dom'

import Totem from './pages/totem/Totem'
import CheckIn from './pages/check-in/CheckIn'
import Marina from './pages/marina/Marina'
import Reservation from './pages/reservation/Reservation'
import HourlyOccupation from './pages/marina/hourly-occupation/HourlyOccupation'
import HourlyPrediction from './pages/marina/hourly-prediction/HourlyPrediction'

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/" element={<Totem />} />
      <Route path="/customer/:reservation" element={<CheckIn />} />
      <Route path="/customer/schedule" element={<Reservation />} />
      <Route path="/marina" element={<Marina />} />
      <Route path="/marina/reservation/:locator" element={<Reservation />} />
      <Route path="/marina/reservation/individual" element={<Reservation />} />
      <Route path="/marina/reservation/new" element={<Reservation />} />
      <Route
        path="/marina/reservation/new/:selectedDeparture"
        element={<Reservation />}
      />
      <Route path="/marina/hourly-occupation" element={<HourlyOccupation />} />
      <Route path="/marina/hourly-prediction" element={<HourlyPrediction />} />
    </Switch>
  </Router>
)

export default Routes
