export const VesselStatusTypes = [
  { id: 1, name: 'GARAGE', description: 'Garagem' },
  { id: 2, name: 'Abastecimento', description: 'Abastecimento' },
  { id: 3, name: 'Finalizado', description: 'Finalizado' },
  { id: 4, name: 'Higiene & Limpeza', description: 'Ducha & Limpeza' },
  { id: 5, name: 'NAVIGATING', description: 'Navegação' },
  { id: 6, name: 'Reparo Externa', description: 'Reparo Externo' },
  { id: 7, name: 'Entrando', description: 'Entrando' },
  { id: 8, name: 'Saindo', description: 'Saindo' },
  { id: 9, name: 'Oficina', description: 'Oficina' }
] // VesselStatusTypes

export const getVesselStatus = (statusCode: number) => {
  const name = VesselStatusTypes.find(
    manufacturer => manufacturer.id === Number(statusCode)
  )?.description
  return name
}
