import { AxiosError } from 'axios'

export const errorInterceptor = (error: AxiosError) => {
  if (error.message === 'Network Error') {
    return new Error('Erro de conexão')
  }

  if (error.response?.status === 403) {
    return new Error('Falha na solicitação')
  }

  if (error.response?.status === 401) {
    window.location.href = '/login'
  }

  return error
}
