import { Button, TextField, TextareaAutosize } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ptBR } from 'date-fns/locale'

import PersonIcon from '@mui/icons-material/Person'
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency'
import GroupsIcon from '@mui/icons-material/Groups'
import EditCalendarIcon from '@mui/icons-material/EditCalendar'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import WaterIcon from '@mui/icons-material/Water'
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied'
import FollowTheSignsOutlinedIcon from '@mui/icons-material/FollowTheSignsOutlined'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import BlockIcon from '@mui/icons-material/Block'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import SearchIcon from '@mui/icons-material/Search'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'

import React, { useEffect, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Jet from '../../../assets/images/Jetski.svg'
import Lancha from '../../../assets/images/Lancha.svg'
import Placeholder from '../../../assets/images/placeholder.png'
import Header from '../../components/header/Header'

import styles from './Reservation.module.scss'

import ReservationConfirmationModal from './components/reservation-confirmation-modal/ReservationConfirmationModal'
import SearchVesselModal from './components/search-vessel-modal/SearchVesselModal'
import SelectReservationModal from './components/select-reservation-modal/SelectReservationModal'
import {
  formatDocument,
  formatPhone,
  vesselTypeOptions
} from '../../utils/utils'
import { isEmpty } from '../../utils/validators'
import { movementService } from '../../services/ms-nts'
import { dateToString, toDate, toInt } from '../../utils/auxiliary-functions'
import { getVesselStatus } from '../../utils/types'
import ImageLightBox from './components/image-lightbox/ImageLightbox'
import Loader from '../../components/loader/loader'

const Reservation: React.FC = () => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [openSearchVesselModal, setOpenSearchVesselModal] = useState(false)
  const [openSelectReservationModal, setOpenSelectReservationModal] =
    useState(false)
  const [tempReservation, setTempReservation] = useState(false)
  const [modalType, setModalType] = useState('')
  const [reservation, setReservation] = useState({
    new: false,
    individual: false,
    locator: '',
    move: '',
    status: '',
    date: new Date(),
    departure: '',
    arrives: '',
    navigationPlan: '',
    vesselId: '',
    vesselName: '',
    vesselEnrollment: '',
    vesselModel: '',
    vesselBrand: '',
    vesselType: '',
    vesselImage: '',
    vesselIndAlert: 0,
    vesselIndRestriction: 0,
    vesselOwners: [] as any,
    ownerCheckIn: {} as any,
    passengers: '5',
    sailorRequested: false,
    partnerId: 0,
    partnerName: ''
  })
  const navigate = useNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [state] = useState<any>(location.state)
  const stateReservation = state?.tempReservation

  const { locator = '' } = useParams<'locator'>()
  const reservationType = location.pathname.split('/')[3]
  const hasDepartureHour = location.pathname.split('/')[4]
  const isCustomer = location.pathname.split('/')[2] === 'schedule'
  const [loading, setLoading] = useState<boolean>(false)

  const handleDates = (date: Date) => {
    setReservation({
      ...reservation,
      date
    })
  }

  const handleConfirmationModal = (
    type: string,
    temporaryReservation?: any
  ) => {
    setTempReservation(temporaryReservation || tempReservation)
    setOpenConfirmationModal(true)
    setModalType(type)
  }

  const handleSearchVesselModal = () => {
    setOpenSearchVesselModal(true)
  }
  const handleSelectReservationModal = () => {
    setOpenSelectReservationModal(true)
  }

  const handleCloseModal = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') return
    setOpenConfirmationModal(false)
    setOpenSearchVesselModal(false)
    setOpenSelectReservationModal(false)
    setModalType('')
  }

  const confirm = () => {
    navigate(isCustomer ? '/' : '/marina')
  }

  useEffect(() => {
    getReservation()
  }, [])

  useEffect(() => {
    if (hasDepartureHour) {
      setOpenSelectReservationModal(true)
    }
  }, [])

  const getReservation = async () => {
    setLoading(true)
    if (isCustomer) {
      setReservation({
        new: false,
        individual: false,
        locator: stateReservation.locator,
        move: stateReservation.move,
        status: stateReservation.status,
        date: new Date(reservation.date),
        departure: reservation.departure,
        arrives: reservation.arrives,
        passengers: stateReservation.passengers,
        sailorRequested: stateReservation.sailorRequested,
        navigationPlan: stateReservation.navigationPlan,
        vesselId: stateReservation.vesselId,
        vesselName: stateReservation.vesselName,
        vesselEnrollment: stateReservation.vesselEnrollment,
        vesselModel: stateReservation.vesselModel,
        vesselType: stateReservation.vesselType,
        vesselBrand: stateReservation.vesselBrand,
        vesselImage: stateReservation.vesselImage,
        vesselIndAlert: stateReservation.vesselIndAlert,
        vesselIndRestriction: stateReservation.vesselIndRestriction,
        vesselOwners: stateReservation.vesselOwners,
        ownerCheckIn: stateReservation.ownerCheckIn,
        partnerId: stateReservation.partnerId,
        partnerName: stateReservation.partnerName
      })
    }
    if (
      reservationType !== 'individual' &&
      reservationType !== 'new' &&
      !isCustomer
    ) {
      if (locator) {
        await movementService.getByLocator(locator).then((movement: any) => {
          setReservation({
            new: false,
            individual: false,
            locator: movement.locator,
            move: movement.move,
            status: movement.status,
            date: toDate(movement.navegation, 'yyyy-mm-ddTHH:MM:ss.sssZ'),
            departure: dateToString(movement.departure, 'HH:MM'),
            arrives: dateToString(movement.forecast, 'HH:MM'),
            navigationPlan: movement.navigationPlan,
            vesselId: movement.vesselId,
            vesselName: movement.vesselName,
            vesselEnrollment: movement.vesselEnrollment,
            vesselType: movement.vesselType,
            vesselModel: movement.vesselModel,
            vesselBrand: movement.vesselBrand,
            vesselImage: movement.vesselImage,
            vesselIndAlert: movement.vesselIndAlert,
            vesselIndRestriction: movement.vesselIndRestriction,
            vesselOwners: movement.vesselOwners,
            ownerCheckIn: movement.ownerCheckIn,
            passengers: movement.qtyPeople,
            sailorRequested: movement.sailorRequested,
            partnerId: movement.partnerId,
            partnerName: movement.partnerNamem
          })
        })
      }
    }
    setLoading(false)
  }

  const fillSelectedVessel = (selectedVessel: any) => {
    handleCloseModal('e', 'reason')
    setReservation({
      new: reservationType === 'new',
      individual: reservationType === 'individual',
      locator: '',
      move: '0',
      status: selectedVessel.status,
      date: new Date(reservation.date),
      departure: reservation.departure,
      arrives: reservation.arrives,
      navigationPlan: selectedVessel.navigationPlan,
      vesselId: selectedVessel.vesselId,
      vesselName: selectedVessel.vesselName,
      vesselEnrollment: selectedVessel.vesselEnrollment,
      vesselModel: selectedVessel.vesselModel,
      vesselType: selectedVessel.vesselType,
      passengers: selectedVessel.passengers,
      vesselBrand: selectedVessel.vesselBrand,
      vesselImage: selectedVessel.vesselImage,
      vesselIndAlert: selectedVessel.vesselIndAlert,
      vesselIndRestriction: selectedVessel.vesselIndRestriction,
      vesselOwners: selectedVessel.vesselOwners,
      ownerCheckIn: selectedVessel.ownerCheckIn,
      sailorRequested: selectedVessel.sailorRequested,
      partnerId: selectedVessel.partnerId,
      partnerName: selectedVessel.partnerName
    })
  }

  const fillReservationSchedule = (reservationSchedule: any) => {
    handleCloseModal('event', 'reason')
    setReservation({
      ...reservation,
      date: new Date(reservationSchedule.date),
      departure: reservationSchedule.departure,
      arrives: reservationSchedule.arrives
    })

    if (isCustomer) {
      enqueueSnackbar(
        `Programe-se para chegar no horário solicitado.
        Dúvidas, tolerância ou atrasos pergunte a um de nossos operadores.
        `,
        {
          variant: 'info',
          autoHideDuration: 7000
        }
      )
    }
  }

  return (
    <div className={styles.reservation}>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <div className="d-flex flex-column align-items-center">
          {isCustomer && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={`${styles.logoutButton} mt-1 me-4`}
              onClick={() => handleSelectReservationModal()}
              sx={{ width: 310 }}
            >
              {reservation.departure === ''
                ? 'HORÁRIOS DISPONÍVEIS'
                : 'ESCOLHER OUTRO HORÁRIO'}
              <QueryBuilderIcon className="ms-2" />
            </Button>
          )}
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between mt-2 align-items-center">
              {!loading &&
                reservationType !== 'individual' &&
                reservationType !== 'new' &&
                !isCustomer && (
                  <>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      id="reservation"
                      label="Localizador"
                      name="reservation"
                      type="text"
                      sx={{ width: 180 }}
                      onChange={e =>
                        setReservation({
                          ...reservation,
                          locator: e.target.value.toUpperCase()
                        })
                      }
                      disabled
                      color="primary"
                      inputProps={{
                        maxLength: 6,
                        style: { textTransform: 'uppercase' }
                      }}
                      className="submit me-3"
                      value={reservation.locator}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      id="status"
                      label="Status"
                      name="status"
                      type="text"
                      sx={{ width: 180 }}
                      disabled
                      color="primary"
                      className="submit me-3"
                      value={
                        reservation.status !== ''
                          ? getVesselStatus(toInt(reservation.status))
                          : ''
                      }
                    />
                  </>
                )}
              {reservation.departure !== '' && (
                <>
                  <LocalizationProvider
                    adapterLocale={ptBR}
                    dateAdapter={AdapterDateFns}
                  >
                    <DesktopDatePicker
                      label="Data"
                      sx={{ width: 180 }}
                      value={reservation.date}
                      onChange={(newValue: any) => {
                        handleDates(newValue)
                      }}
                      disabled
                      className="mt-2 me-3"
                      minDate={new Date()}
                    />
                  </LocalizationProvider>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    autoComplete="off"
                    sx={{ width: 180 }}
                    id="departure"
                    label="Previsão de Saída"
                    name="departure"
                    type="text"
                    onChange={e =>
                      setReservation({
                        ...reservation,
                        departure: e.target.value
                      })
                    }
                    disabled
                    color="primary"
                    className="submit me-3"
                    value={reservation.departure}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    sx={{ width: 180 }}
                    id="arrives"
                    label="Previsão de Retorno"
                    name="arrives"
                    autoComplete="off"
                    autoFocus
                    disabled
                    onChange={e =>
                      setReservation({
                        ...reservation,
                        arrives: e.target.value
                      })
                    }
                    color="primary"
                    className="submit"
                    value={reservation.arrives}
                  />
                </>
              )}
            </div>
            {reservation.vesselName !== '' && (
              <>
                <div className="d-flex justify-content-between mt-2align-items-center">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    id="vessel"
                    label="Embarcação"
                    name="vessel"
                    type="text"
                    sx={{ width: 220 }}
                    onChange={e =>
                      setReservation({
                        ...reservation,
                        vesselName: e.target.value
                      })
                    }
                    disabled
                    color="primary"
                    className="submit me-4"
                    value={reservation.vesselName}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    id="vesselBrand"
                    label="Marca"
                    name="vesselBrand"
                    type="text"
                    sx={{ width: 220 }}
                    disabled
                    color="primary"
                    className="submit me-4"
                    value={reservation.vesselBrand || ''}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    id="vesselModel"
                    label="Modelo"
                    name="vesselModel"
                    type="text"
                    sx={{ width: 220 }}
                    disabled
                    color="primary"
                    className="submit me-4"
                    value={reservation.vesselModel || ''}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    id="vesselType"
                    label="Tipo"
                    name="vesselType"
                    type="text"
                    sx={{ width: 220 }}
                    disabled
                    color="primary"
                    className="submit"
                    value={
                      vesselTypeOptions.find(
                        (type: any) => type.id === toInt(reservation.vesselType)
                      )?.label || ''
                    }
                  />
                </div>
                <h6 className="mt-3">Plano de Navegação</h6>
                <TextareaAutosize
                  value={
                    reservation.navigationPlan !== ''
                      ? reservation.navigationPlan
                      : 'Plano de Navegação não informado (opcional para moto aquática)'
                  }
                  minRows={3}
                  style={{
                    maxHeight: 70,
                    minWidth: 980,
                    minHeight: 65,
                    maxWidth: 640,
                    borderRadius: 6,
                    backgroundColor: '#303A4A',
                    padding: 10,
                    color: 'white',
                    fontSize: 14
                  }}
                  placeholder="Digite o seu plano de navegação"
                  onChange={e =>
                    setReservation({
                      ...reservation,
                      navigationPlan: e.target.value
                    })
                  }
                  onResize={undefined}
                  onResizeCapture={undefined}
                  disabled
                />
              </>
            )}
          </div>
          {reservation.vesselName === '' && (
            <>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={`${styles.logoutButton} mt-4 me-2`}
                onClick={() => handleSelectReservationModal()}
                sx={{ width: 310 }}
              >
                {reservation.departure === ''
                  ? 'HORÁRIOS DISPONÍVEIS'
                  : 'ESCOLHER OUTRO HORÁRIO'}
                <QueryBuilderIcon className="ms-2" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={`${styles.logoutButton} mt-4 me-2`}
                disabled={
                  !reservation.departure ||
                  !reservation.date ||
                  reservation.arrives.length < 4
                }
                onClick={() => handleSearchVesselModal()}
                sx={{ width: 310 }}
              >
                BUSCAR EMBARCAÇÃO
                <SearchIcon className="ms-2" />
              </Button>
            </>
          )}
          {!isEmpty(reservation.vesselName) && (
            <div className="d-flex justify-content-between w-100 mt-4 ps-2 pe-4">
              <div className="d-flex flex-column align-items-center">
                {!reservation.vesselImage.includes('static/media') ||
                !reservation.vesselImage ||
                reservation.vesselImage === '' ? (
                  <ImageLightBox
                    containerStyle={styles.imageWrapper}
                    src={reservation.vesselImage}
                    alt="Embarcação"
                    theftIndicator={reservation.vesselIndAlert === 1}
                    financialIndicator={reservation.vesselIndRestriction === 1}
                    noBackground
                  />
                ) : (
                  <img
                    src={Placeholder}
                    alt="Imagem placeholder"
                    className={styles.imagePlaceholder}
                  />
                )}
              </div>
              <div className="d-flex flex-column align-items-center">
                <p>REGISTRO</p>
                {reservation.vesselType === '33' ? (
                  <span className="d-flex justify-content-center">
                    <img
                      alt="imagem de jet-ski"
                      src={Jet}
                      className="jet"
                      style={{ height: 40, width: 40 }}
                    />
                  </span>
                ) : (
                  <span className="d-flex me-4 align-items-center">
                    <img
                      src={Lancha}
                      alt="imagem de lancha"
                      className="jet"
                      style={{ height: 40, width: 40 }}
                    />
                  </span>
                )}
                <p style={{ fontSize: '1.1rem', fontWeight: '500' }}>
                  {reservation.vesselEnrollment}
                </p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <p>RESPONSÁVEL</p>
                <PersonIcon sx={{ fontSize: 40 }} />
                <p>{formatDocument(reservation.ownerCheckIn?.registration)}</p>
                <p className="text-center">{reservation.ownerCheckIn?.name}</p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <p>EMERGÊNCIA</p>
                <ContactEmergencyIcon sx={{ fontSize: 40 }} />
                <div className="d-flex">
                  <div className="d-flex flex-column align-items-center">
                    {!isEmpty(reservation.ownerCheckIn?.contactName) ? (
                      <div>
                        <p>{reservation.ownerCheckIn?.contactName}</p>
                        <p>
                          {formatPhone(reservation.ownerCheckIn?.contactPhone)}
                        </p>
                      </div>
                    ) : (
                      <p className="mt-2 text-center">NÃO INFORMADO</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <p>PASSAGEIRO(S)</p>
                <GroupsIcon sx={{ fontSize: 40 }} />{' '}
                {!isEmpty(reservation.passengers) ? (
                  <h3>{reservation.passengers}</h3>
                ) : (
                  <p className="mt-2">NÃO INFORMADO</p>
                )}
              </div>
              <div className="d-flex flex-column align-items-center">
                <p>MARINHEIRO</p>
                <SensorOccupiedIcon sx={{ fontSize: 40 }} />{' '}
                <p className="mt-2">
                  {reservation.sailorRequested ? 'SIM' : 'NÃO'}
                </p>
              </div>
            </div>
          )}

          <div
            className={`${styles.confirmReservation}
          ${isCustomer && reservation.departure !== '' ? styles.isCustomer : ''}
          ${reservation.vesselName === '' ? styles.noVessel : ''} ${
              reservation.departure === '' ? styles.noDeparture : ''
            }`}
          >
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              className={`${styles.logoutButton} mt-4`}
              onClick={() => navigate(-1)}
            >
              Voltar
              <KeyboardArrowLeftIcon />
            </Button>
            {reservationType !== 'individual' &&
              reservationType !== 'new' &&
              !isCustomer &&
              toInt(reservation.move) === 1 && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${styles.logoutButton} mt-4 me-0`}
                    onClick={() => handleConfirmationModal('confirmDescent')}
                  >
                    CONFIRMAR DESCIDA
                    <KeyboardDoubleArrowDownIcon className="ms-2" />
                  </Button>
                  <Button
                    variant="contained"
                    color="inherit"
                    sx={{
                      backgroundColor: 'yellow',
                      width: 226,
                      color: 'black'
                    }}
                    size="large"
                    className={`${styles.logoutButton} mt-4 me-5`}
                    onClick={() => handleConfirmationModal('reject')}
                  >
                    REJEITAR DESCIDA
                    <BlockIcon className="ms-2" />
                  </Button>
                </>
              )}
            {reservationType !== 'individual' &&
              reservationType !== 'new' &&
              !isCustomer &&
              toInt(reservation.move) === 4 && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={`${styles.logoutButton} mt-4 me-0`}
                  onClick={() => handleConfirmationModal('finishAscent')}
                >
                  FINALIZAR SUBIDA
                  <WaterIcon className="ms-2" />
                </Button>
              )}
            {reservationType !== 'individual' &&
              reservationType !== 'new' &&
              !isCustomer &&
              toInt(reservation.move) === 3 && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={`${styles.logoutButton} mt-4 me-0`}
                  onClick={() => handleConfirmationModal('finishDescent')}
                >
                  FINALIZAR DESCIDA
                  <WaterIcon className="ms-2" />
                </Button>
              )}
            {toInt(reservation.move) === 2 && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={`${styles.logoutButton} mt-4 me-0`}
                  onClick={() => handleConfirmationModal('confirmAscent')} // acao no operador para subir a embarcacao
                >
                  CONFIRMAR SUBIDA
                  <KeyboardDoubleArrowUpIcon className="ms-2" />
                </Button>
                <Button
                  variant="contained"
                  color="inherit"
                  sx={{
                    backgroundColor: 'yellow',
                    width: 226,
                    color: 'black'
                  }}
                  size="large"
                  className={`${styles.logoutButton} mt-4 me-5`}
                  onClick={() => handleConfirmationModal('rejectArrive')}
                >
                  REJEITAR SUBIDA
                  <BlockIcon className="ms-2" />
                </Button>
              </>
            )}
            {reservationType === 'individual' && reservation.individual && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={`${styles.logoutButton} mt-4 me-5`}
                onClick={() => handleConfirmationModal('individual')}
              >
                CONFIRMAR SAÍDA PONTUAL
                <FollowTheSignsOutlinedIcon className="ms-2" />
              </Button>
            )}

            {reservationType === 'new' && reservation.new && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={`${styles.logoutButton} mt-4 me-5`}
                disabled={reservation.arrives.length < 4}
                onClick={() => handleConfirmationModal('new', reservation)}
              >
                CONFIRMAR AGENDAMENTO
                <EditCalendarIcon className="ms-2" />
              </Button>
            )}

            {isCustomer && (
              <div className="d-flex">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={reservation.arrives.length < 4}
                  className={`${styles.logoutButton} mt-4 me-5`}
                  onClick={() =>
                    handleConfirmationModal('customer', reservation)
                  }
                >
                  CONFIRMAR AGENDAMENTO
                  <EditCalendarIcon className="ms-2" />
                </Button>
              </div>
            )}
            <div />
          </div>
        </div>
      )}

      <ReservationConfirmationModal
        open={openConfirmationModal}
        handleClose={handleCloseModal}
        height="fit-content"
        width={450}
        reservation={reservation.locator}
        type={modalType}
        onConfirm={confirm}
        onCancel={handleCloseModal}
        onCancelReservation={confirm}
        onLogout={confirm}
        tempReservation={reservation}
      />
      <SearchVesselModal
        open={openSearchVesselModal}
        handleClose={handleCloseModal}
        height="fit-content"
        width={430}
        onCancel={handleCloseModal}
        onSelectedVessel={(e: any) => fillSelectedVessel(e)}
        isNewReservation={reservationType === 'new'}
      />
      <SelectReservationModal
        partnerId={reservation.partnerId}
        open={openSelectReservationModal}
        handleClose={handleCloseModal}
        onCancel={handleCloseModal}
        height={540}
        width="100vw"
        window={5}
        onSelectReservation={(e: any) => fillReservationSchedule(e)}
        departureHour={hasDepartureHour}
      />
    </div>
  )
}

export default Reservation
