import React from 'react'

import { PatternFormat } from 'react-number-format'

interface CustomProps {
  onChange: (event: {
    target: { name: string; value: string; defaultValue?: string }
  }) => void
  name: string
  value: string
  defaultValue: string
}

export const PhoneFormat = React.forwardRef<CustomProps>(function PhoneFormat(
  props: any,
  ref
) {
  const { onChange, value, ...other } = props

  const getMask = (term: string) => {
    if (term?.length < 9) {
      return '##################'
    }

    if (term?.length === 11) {
      return '(##) #####-####'
    }

    return '(##) ####-#####'
  }

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      format={getMask(value)}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,

            value: values.value
          }
        })
      }}
      value={value === undefined ? '' : value}
    />
  )
})

export const NumberFormatDocument = React.forwardRef<CustomProps>(
  function NumberFormatDocument(props: any, ref) {
    const { onChange, value, ...other } = props

    const getMask = (term: string) => {
      if (term?.length < 11) {
        return '##################'
      }

      if (term?.length === 11) {
        return '###.###.###-####'
      }

      return '##.###.###/####-##'
    }

    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        format={getMask(value)}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,

              value: values.value
            }
          })
        }}
        value={value === undefined ? '' : value}
      />
    )
  }
)

export const NumberFormatCPF = React.forwardRef<CustomProps>(
  function NumberFormatCPF(props: any, ref) {
    const { onChange, value, ...other } = props

    const getMask = (term: string) => {
      if (term?.length < 11) {
        return '##################'
      }

      if (term?.length === 11) {
        return '###.###.###-##'
      }

      return '###.###.###-##'
    }

    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        format={getMask(value)}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,

              value: values.value
            }
          })
        }}
        value={value === undefined ? '' : value}
      />
    )
  }
)

export const HourFormat = React.forwardRef<CustomProps>(function HourFormat(
  props: any,
  ref
) {
  const { onChange, value, ...other } = props

  const getMask = (term: string) => {
    if (term.length > 0) {
      return '##:##'
    }
    return '##:##'
  }

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      format={getMask(value)}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,

            value: values.value
          }
        })
      }}
      value={value === undefined ? '' : value}
    />
  )
})

export const CellPhoneFormat = React.forwardRef<CustomProps>(
  function CellPhoneFormat(props: any, ref) {
    const { onChange, value, ...other } = props

    const getMask = (term: string) => {
      if (term?.length < 9) {
        return '##################'
      }

      return '(##) #####-####'
    }

    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        format={getMask(value)}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,

              value: values.value
            }
          })
        }}
        value={value === undefined ? '' : value}
      />
    )
  }
)

export const ZipCodeFormat = React.forwardRef<CustomProps>(
  function ZipCodeFormat(props: any, ref) {
    const { onChange, value, ...other } = props

    const getMask = (term: string) => {
      if (term?.length === 8) return '#####-###'

      return '#####-###'
    }

    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        format={getMask(value)}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,

              value: values.value
            }
          })
        }}
        value={value === undefined ? '' : value}
      />
    )
  }
)

export const YearFormat = React.forwardRef<CustomProps>(function YearFormat(
  props: any,
  ref
) {
  const { onChange, value, ...other } = props

  const getMask = (term: string) => {
    if (term?.length === 8) return '####'

    return '####'
  }

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      format={getMask(value)}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,

            value: values.value
          }
        })
      }}
      value={value === undefined ? '' : value}
    />
  )
})
