import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, TextField, Typography } from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  ArcElement,
  Tooltip
} from 'chart.js'
import { Bar, Doughnut } from 'react-chartjs-2'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import { faker } from '@faker-js/faker'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import styles from './HourlyPrediction.module.scss'
import Header from '../../../components/header/Header'

import Lancha from '../../../../assets/images/Lancha.svg'
import Jet from '../../../../assets/images/Jetski.svg'
import Loader from '../../../components/loader/loader'

const HourlyPrediction: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend,
    ArcElement
  )

  const barOptions = {
    responsive: true,
    gridLines: {
      display: false
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          color: '#FFFFFF',
          font: {
            size: 9,
            family: "'Montserrat', sans-serif"
          }
        }
      },
      datalabels: {
        color: 'white',
        font: {
          size: 9,
          family: "'Montserrat', sans-serif"
        }
      }
    },
    maintainAspectRatio: true,
    scales: {
      x: {
        ticks: {
          color: 'white',
          font: {
            size: 9,
            family: "'Montserrat', sans-serif"
          }
        },
        grid: {
          display: true,
          tickColor: 'white',
          drawOnChartArea: false,
          color: 'white'
        },
        border: {
          color: 'white'
        }
      },
      y: {
        ticks: {
          color: 'white',
          font: {
            size: 9,
            family: "'Montserrat', sans-serif"
          }
        },
        grid: {
          display: true,
          tickColor: 'white',
          drawOnChartArea: false,
          color: 'white'
        },
        border: {
          color: 'white'
        }
      }
    }
  }

  const doughnutOptions = {
    responsive: true,
    gridLines: {
      display: false
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          color: '#FFFFFF',
          font: {
            size: 9,
            family: "'Montserrat', sans-serif"
          }
        }
      },
      datalabels: {
        color: 'white',
        font: {
          size: 9,
          family: "'Montserrat', sans-serif"
        },
        formatter(value: any) {
          return `${value}%`
        }
      }
    },

    maintainAspectRatio: true
  }

  const predictedDailyLabels = [
    '11/07/2023 - Terça',
    '12/07/2023 - Quarta',
    '13/07/2023 - Quinta',
    '14/07/2023 - Sexta',
    '15/07/2023 - Sábado',
    '16/07/2023 - Domingo',
    '17/07/2023 - Segunda'
  ]

  const predictedHourlyLabels = [
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00'
  ]
  const predictedDailyData = {
    labels: predictedDailyLabels,
    datasets: [
      {
        label: 'Previstos',
        data: predictedDailyLabels.map(() =>
          faker.number.int({ min: 0, max: 60 })
        ),
        backgroundColor: '#3232D6'
      },
      {
        label: 'Realizados',
        data: predictedDailyLabels.map(() =>
          faker.number.int({ min: 0, max: 60 })
        ),
        backgroundColor: '#87B972',
        datalabels: {
          display: false
        }
      },
      {
        label: 'Pendentes',
        data: predictedDailyLabels.map(() =>
          faker.number.int({ min: 0, max: 60 })
        ),
        backgroundColor: '#FFB034',
        datalabels: {
          display: false
        }
      },
      {
        label: 'Cancelados',
        data: predictedDailyLabels.map(() =>
          faker.number.int({ min: 0, max: 60 })
        ),
        backgroundColor: '#CF4147',
        datalabels: {
          display: false
        }
      }
    ]
  }
  const predictedHourlyData = {
    labels: predictedHourlyLabels,
    datasets: [
      {
        label: 'Previstos',
        data: predictedHourlyLabels.map(() =>
          faker.number.int({ min: 0, max: 60 })
        ),
        backgroundColor: '#3232D6',
        barThickness: 15
      },
      {
        label: 'Realizados',
        data: predictedHourlyLabels.map(() =>
          faker.number.int({ min: 0, max: 60 })
        ),
        backgroundColor: '#87B972',
        datalabels: {
          display: false
        },
        barThickness: 15
      }
    ]
  }

  const data = {
    labels: ['Lancha', 'Jet-ski'],
    datasets: [
      {
        data: [33, 67],
        backgroundColor: ['#00007A', '#91A9AF'],
        borderWidth: 0
      }
    ]
  }

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2500)
  }, [])

  return (
    <>
      <Header title="Previsão Semanal" />
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.hourlyPrediction}>
          <section
            className={`d-flex justify-content-between w-100 ${styles.infosWrapper}`}
          >
            <TextField
              value="11/07/2023"
              label="Data Pico"
              variant="outlined"
              disabled
              sx={{ width: 160 }}
              size="small"
            />
            <TextField
              value="15:00"
              label="Horário Pico"
              variant="outlined"
              disabled
              sx={{ width: 160 }}
              size="small"
            />
            <TextField
              value="210"
              label="Pessoas Previstas"
              variant="outlined"
              disabled
              sx={{ width: 160 }}
              size="small"
            />
            <TextField
              value="500"
              label="Pessoas Estimadas"
              variant="outlined"
              disabled
              sx={{ width: 160 }}
              size="small"
            />
          </section>
          <section className={styles.chartsWrapper}>
            <div className="d-flex mt-3">
              <div className="d-flex flex-column align-items-center">
                <div style={{ width: '20vw', height: '190px' }}>
                  <Doughnut
                    data={data}
                    options={doughnutOptions}
                    className="mt-3"
                  />
                </div>

                <div className="ms-4 d-flex mt-3 justify-content-start w-100">
                  <span className="d-flex me-4 align-items-center">
                    <img
                      src={Lancha}
                      alt="imagem de lancha"
                      className="jet me-2"
                      style={{ height: 22, width: 22 }}
                    />
                    <span className="bolder mt-1">123</span>
                  </span>
                  <span className="d-flex ms-4 align-items-center">
                    <span className="d-flex justify-content-center">
                      <img
                        alt="imagem de jet-ski"
                        src={Jet}
                        className="jet me-2"
                        style={{ height: 22, width: 22 }}
                      />
                    </span>
                    <span className="bolder mt-1">247</span>
                  </span>
                </div>
              </div>
              <Typography
                className={`${styles.dailyPrediction}`}
                sx={{ marginTop: '10px !important' }}
              >
                Check-in
              </Typography>
              <div style={{ width: '80vw' }}>
                <Bar
                  className="mt-5"
                  options={barOptions}
                  data={predictedDailyData}
                  height={70}
                />
              </div>
            </div>
            <Bar
              className="mt-4"
              options={barOptions}
              data={predictedHourlyData}
              height={48}
            />
          </section>
          <div className="d-flex justify-content-between mt-2">
            <Button
              variant="outlined"
              color="inherit"
              className={`${styles.logoutButton} mt-3`}
              onClick={() => navigate(-1)}
            >
              Voltar
              <KeyboardArrowLeftIcon />
            </Button>
            <LocalizationProvider
              adapterLocale={ptBR}
              dateAdapter={AdapterDateFns}
            >
              <DesktopDatePicker
                label="Previsão Seguinte"
                sx={{ width: 150 }}
                slotProps={{
                  textField: {
                    variant: 'standard'
                  }
                }}
                value={new Date()}
                onChange={() => navigate(0)}
                className="mt-2 me-3"
                minDate={new Date()}
              />
            </LocalizationProvider>
            <div style={{ width: '80px' }} />
          </div>
        </div>
      )}
    </>
  )
}

export default HourlyPrediction
