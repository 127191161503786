import React, { useEffect, useRef, useState } from 'react'

import EditCalendarIcon from '@mui/icons-material/EditCalendar'
import FollowTheSignsOutlinedIcon from '@mui/icons-material/FollowTheSignsOutlined'
import TodayIcon from '@mui/icons-material/Today'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import SportsScoreIcon from '@mui/icons-material/SportsScore'
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import UsbIcon from '@mui/icons-material/Usb'

import { DataGrid, GridColDef } from '@mui/x-data-grid'
import useSound from 'use-sound'

import {
  Button,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  TextField,
  ThemeProvider
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import HandymanIcon from '@mui/icons-material/Handyman'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import Jet from '../../../assets/images/Jetski.svg'
import Lancha from '../../../assets/images/Lancha.svg'
import Navegando from '../../../assets/images/Navegando.svg'
import Header from '../../components/header/Header'
import theme from '../../theme/theme'
import styles from './Marina.module.scss'
import { authService, movementService } from '../../services/ms-nts'
import { removeFormatDate } from '../../utils/masks'
import { dateToString, toInt } from '../../utils/auxiliary-functions'
import { isEmpty } from '../../utils/validators'
import Abastecimento from '../../../assets/images/Abastecimento.svg'
import Manutenca0_externa from '../../../assets/images/Manutenca0_externa.svg'
import Movimento_entrada from '../../../assets/images/Movimento_entrada.svg'
import Movimento_saida from '../../../assets/images/Movimento_saida.svg'
import Higiene from '../../../assets/images/Higiene.svg'
import descidaSolicitada from '../../../assets/sounds/descida_solicitada.mp3'
import subidaSolicitada from '../../../assets/sounds/subida_solicitada.mp3'
import bip from '../../../assets/sounds/bip.mp3'

export default function Marina() {
  const navigate = useNavigate()
  const [filter, setFilter] = useState('')
  const [selectedFilter, setSelectedFilter] = useState('All')
  const [filteringMove, setFilteringMove] = useState(false)
  const [loading, setLoading] = useState(false)
  const [qtdMovement, setQtdMovement] = useState(0)
  const [qtdDown, setQtdDown] = useState(0)
  const [qtdNavigate, setQtdNavigate] = useState(0)
  const [qtdRise, setQtdRise] = useState(0)
  const [qtfFueling, setQtdFueling] = useState(0)
  const [qtdFinished, setQtdFinished] = useState(0)
  const [selectedSound, setSelectedSound] = useState(
    localStorage.getItem('sound') || 'voice'
  )
  const [playDescidaSolicitada] = useSound(descidaSolicitada)
  const [playSubidaSolicitada] = useSound(subidaSolicitada)
  const [playBip] = useSound(bip)
  const inputRef: any = useRef(null)

  const [port, setPort] = useState<any>()

  useEffect(() => {
    openSerialPort()
  }, [])

  const openSerialPort = async () => {
    const ports = await navigator.serial.getPorts()

    if (ports.length && ports[0].writable !== null) {
      setPort(ports[0])
    } else {
      const requestedPort = await navigator.serial.requestPort()
      if (requestedPort) {
        await requestedPort.open({
          baudRate: 9600
        })
        setPort(requestedPort)
      } else {
        openSerialPort()
      }
    }
  }

  const getReservation = () => {
    return <RemoveRedEyeIcon className="isClickable" color="inherit" />
  }

  const getVessel = (params: any) => {
    return (
      <div
        className={`d-flex flex-column align-items-center justify-content-center w-100 mb-2 ${styles.vesselWrapper}`}
      >
        <div className="d-flex justify-content-center mb-1">
          <img
            alt="JetSki"
            src={toInt(params.row.vesselType) === 33 ? Jet : Lancha}
            className="jet mt-3"
            style={{
              height: 36
            }}
          />
          <small className="ms-1 mt-3">{`${
            toInt(params.row.vesselType) === 33 ? '10' : params.row.vesselSize
          }'`}</small>
        </div>
        <span
          className={styles.vesselColor}
          style={{ backgroundColor: params.row.vesselColor }}
        />
      </div>
    )
  }

  const getDepartureAndId = (params: any) => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <p>
          {!isEmpty(params.row.departure)
            ? dateToString(params.row.departure, 'HH:MM')
            : ''}
        </p>
        <p>{params.row.vesselIdentifyKey}</p>
      </div>
    )
  }

  const getModelAndOwner = (params: any) => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <p>{params.row.vesselModel}</p>
        <p>{params.row.vesselOwner.name.split(' ')[0]}</p>
      </div>
    )
  }

  const getGarageAndName = (params: any) => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <p>{`${params.row.vesselGarage.shelfName} - ${params.row.vesselGarage.drawerName}`}</p>
        <p>{params.row.vesselName}</p>
      </div>
    )
  }

  const getLocator = (params: any) => {
    return (
      <span className="d-flex flex-column align-items-center justify-content-center">
        <p>{params.row.locator}</p>
        <p>{getCheckInStatus(params.row.checkIn)}</p>
      </span>
    )
  }

  const getCheckInStatus = (status: string) => {
    switch (status) {
      case 'confirmed':
        return 'Efetuado'
      case 'pending':
        return 'Pendente'
      case 'canceled':
        return 'Cancelado'
      case 'finished':
        return 'Finalizado'
      default:
        return ''
    }
  }

  const getMove = (params: any) => {
    switch (params.row.move) {
      case 1:
        return <p className={styles.departureRequested}>Descida Solicitada</p>
      case 2:
        return <p className={styles.arrivesRequested}>Subida Solicitada</p>
      case 3:
        return <p className={styles.departureRequested}>Descida Confirmada</p>
      case 4:
        return <p className={styles.departureRequested}>Subida Confirmada</p>
      case 5:
        return <p className={styles.departureRequested}>Descida Finalizada</p>
      case 6:
        return <p className={styles.departureRequested}>Subida Finalizada</p>
      case 0:
        return <p>-</p>
      case undefined:
        return <p>-</p>
      case null:
        return <p>-</p>
      default:
        return <p>-</p>
    }
  }

  const getStatus = (params: any) => {
    switch (params.row.status) {
      case '1':
        return (
          <div className="d-flex flex-column align-items-center">
            <WarehouseIcon />
            <small>Garagem</small>
          </div>
        )
      case '2':
        return (
          <div className="d-flex flex-column align-items-center">
            <img
              alt="Abastecimento"
              src={Abastecimento}
              className="jet"
              style={{ height: 23, width: 23 }}
            />
            <small>Abastecimento</small>
          </div>
        )
      case '3':
        return (
          <p
            style={{
              marginBottom: 0,
              marginTop: 3,
              backgroundColor: 'green',
              padding: 2,
              paddingLeft: 6,
              paddingRight: 6,
              borderRadius: '8px'
            }}
          >
            Finalizado
          </p>
        )
      case '4':
        return (
          <div className="d-flex flex-column align-items-center">
            <img
              alt="Higiene"
              src={Higiene}
              className="jet"
              style={{ height: 28, width: 28 }}
            />
            <small>Limpeza</small>
          </div>
        )
      case '5':
        return (
          <div className="d-flex flex-column align-items-center">
            <div className={styles.rotating}>
              <img
                alt="Navegando"
                src={Navegando}
                className="jet"
                style={{ height: 23, width: 23 }}
              />
            </div>
            <small>Navegando</small>
          </div>
        )
      case '6':
        return (
          <div className="d-flex flex-column align-items-center">
            <img
              alt="Reparo Externo"
              src={Manutenca0_externa}
              className="jet"
              style={{ height: 30, width: 30 }}
            />
            <small>Reparo Externo</small>
          </div>
        )
      case '7':
        return (
          <div className="d-flex flex-column align-items-center">
            <img
              alt="Entrando"
              src={Movimento_entrada}
              className="jet"
              style={{ height: 32, width: 32 }}
            />
            <small>Entrando</small>
          </div>
        )
      case '8':
        return (
          <div className="d-flex flex-column align-items-center">
            <img
              alt="Saindo"
              src={Movimento_saida}
              className="jet"
              style={{ height: 32, width: 32 }}
            />
            <small>Saindo</small>
          </div>
        )
      case '9':
        return (
          <div className="d-flex flex-column align-items-center">
            <HandymanIcon />
            <small>Oficina</small>
          </div>
        )
      default:
        return <div className="d-flex flex-column align-items-center">-</div>
    }
  }

  const getQueuePosition = (params: any) => {
    if (params.row.queuePosition) {
      return (
        <span className="d-flex flex-column align-items-center justify-content-center">
          <h4>{params.row.queuePosition}</h4>
        </span>
      )
    }

    return (
      <span className="d-flex flex-column align-items-center justify-content-center">
        <p>-</p>
      </span>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'HORA | ID',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      maxWidth: 120,
      renderCell: getDepartureAndId
    },
    {
      field: 'vesselType',
      headerName: 'TIPO | COR',
      flex: 1,
      sortable: true,
      maxWidth: 120,
      headerAlign: 'center',
      renderCell: getVessel
    },
    {
      field: 'vesselName',
      headerName: 'MOD | RESP',
      sortable: true,
      flex: 1,
      headerAlign: 'center',
      renderCell: getModelAndOwner,
      align: 'center',
      minWidth: 120
    },
    {
      field: 'vesselGarage',
      headerName: 'GUARDA | EMB',
      sortable: true,
      flex: 1,
      headerAlign: 'center',
      renderCell: getGarageAndName,
      align: 'center',
      minWidth: 150
    },
    {
      field: 'reservation',
      headerName: 'CHECK-IN',
      sortable: true,
      flex: 1,
      headerAlign: 'center',
      renderCell: getLocator,
      minWidth: 120,
      align: 'center'
    },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 1,
      headerAlign: 'center',
      maxWidth: 120,
      sortable: true,
      align: 'center',
      renderCell: getStatus
    },
    {
      field: 'move',
      headerName: 'CHAMADA',
      flex: 1,
      headerAlign: 'center',
      minWidth: 180,
      sortable: true,
      align: 'center',
      renderCell: getMove
    },
    {
      field: 'queuePosition',
      headerName: 'FILA',
      flex: 1,
      maxWidth: 60,
      headerAlign: 'center',
      sortable: true,
      align: 'center',
      renderCell: getQueuePosition
    },
    {
      field: 'view',
      headerName: '(PN)',
      type: 'text',
      headerAlign: 'center',
      maxWidth: 60,
      renderCell: getReservation,
      flex: 1,
      disableExport: true,
      align: 'center'
    }
  ]

  const [reservations, setReservations] = useState([] as any)
  const [originalReservations, setOriginalReservations] = useState([] as any)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const viewReservation = (params: any) => {
    const value = params.colDef.field
    if (value !== 'view') {
      return
    }
    const { row } = params
    navigate(`/marina/reservation/${row.locator}`)
  }

  const filterReservations = (key?: string, value?: string) => {
    setFilteringMove(false)
    if (key && value) {
      setSelectedFilter(value)
      if (value === 'All')
        return setReservations(
          originalReservations.filter(
            (movement: any) => movement.move !== 6 && movement.status !== '5'
          )
        )
      let filteredReservations: any

      if (key === 'status') {
        filteredReservations = originalReservations.filter(
          (reservation: any) => reservation.status === value
        )
      } else {
        filteredReservations = originalReservations.filter(
          (reservation: any) => reservation.move === toInt(value)
        )
        setFilteringMove(true)
      }
      setReservations(filteredReservations)
    } else {
      const filteredReservations: any = []
      reservations.filter((reservation: any) => {
        if (
          reservation.vesselName
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          reservation.vesselOwner
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          reservation.reservation
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase())
        )
          filteredReservations.push(reservation)
        return reservation
      })
      setReservations(filteredReservations)
    }
    return ''
  }

  useEffect(() => {
    if (filter.length >= 3) {
      filterReservations()
    } else if (filter.length < 3) {
      setReservations(originalReservations)
    }
  }, [filter])

  useEffect(() => {
    getReservations()
    const interval = setInterval(() => {
      getReservations()
    }, 30000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getReservations = async () => {
    setLoading(true)
    inputRef.current?.click()
    const partnerAuth = await authService.currentPartner()
    const dateNavigation = removeFormatDate(new Date().toLocaleDateString())
    const schedules = await movementService.navigation(
      partnerAuth.id,
      dateNavigation
    )
    if (schedules) {
      setReservations(
        schedules.filter(
          (movement: any) => movement.move !== 6 && movement.status !== '5'
        )
      )
      setOriginalReservations(schedules)
      setQtdMovement(
        schedules.filter(
          (movement: any) => movement.move !== 6 && movement.status !== '5'
        ).length
      )
      setQtdDown(
        schedules.filter((movement: any) => movement.move === 1).length
      )
      setQtdNavigate(
        schedules.filter((movement: any) => movement.status === '5').length
      )
      setQtdRise(
        schedules.filter((movement: any) => movement.move === 2).length
      )
      setQtdFueling(
        schedules.filter((movement: any) => movement.status === '2').length
      )
      setQtdFinished(
        schedules.filter((movement: any) => movement.move === 6).length
      )
    }
    setLoading(false)
    setSelectedFilter('All')
  }

  const playSounds = () => {
    const down = reservations?.filter(
      (movement: any) => movement.move === 1
    ).length
    const rise = reservations?.filter(
      (movement: any) => movement.move === 2
    ).length

    if (selectedSound === 'bip' && (down > 0 || rise > 0)) {
      playBip()
    }

    if (selectedSound === 'voice') {
      if (down > 0) {
        playDescidaSolicitada()
      }
      if (rise > 0) {
        if (down > 0) {
          setTimeout(() => {
            playSubidaSolicitada()
          }, 2000)
        } else {
          playSubidaSolicitada()
        }
      }
    }
    setTimeout(() => {
      if (port) {
        sendCommands()
      }
    }, 5000)

    if (selectedSound === 'muted') {
      return ''
    }

    return ''
  }

  const sendCommands = async () => {
    const down = reservations?.filter(
      (movement: any) => movement.move === 1
    ).length
    const rise = reservations?.filter(
      (movement: any) => movement.move === 2
    ).length
    const confirmedDown = reservations?.filter(
      (movement: any) => movement.move === 3
    ).length
    const confirmedRise = reservations?.filter(
      (movement: any) => movement.move === 4
    ).length

    if (down > 0) {
      const string = new TextEncoder().encode('DS1x')
      const writer = port.writable.getWriter()
      await writer.write(string)
      writer.releaseLock()
    }
    if (rise > 0) {
      setTimeout(async () => {
        const string = new TextEncoder().encode('SS1x')
        const writer = port.writable.getWriter()

        await writer.write(string)
        writer.releaseLock()
      }, 5000)
    }
    if (confirmedDown > 0) {
      setTimeout(async () => {
        const string = new TextEncoder().encode('DS0x')
        const writer = port.writable.getWriter()

        await writer.write(string)
        writer.releaseLock()
      }, 10000)
    }
    if (confirmedRise > 0) {
      setTimeout(async () => {
        const string = new TextEncoder().encode('SS0x')
        const writer = port.writable.getWriter()

        await writer.write(string)
        writer.releaseLock()
      }, 15000)
    }

    return ''
  }

  const filterAndPlay = () => {
    filterReservations('none', 'All')
    playSounds()
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const selectSound = (sound: string) => {
    localStorage.setItem('sound', sound)
    setSelectedSound(sound)
    handleClose()
  }

  return (
    <div className={styles.marina}>
      <Header />
      <div className={styles.marinaWrapper}>
        <div className="d-flex justify-content-between align-items-baseline">
          <TextField
            variant="standard"
            margin="normal"
            id="search"
            autoComplete="off"
            label="Pesquisar"
            name="search"
            type="text"
            sx={{ width: 130 }}
            onChange={e => setFilter(e.target.value)}
            color="primary"
            className="submit mt-4 mb-0"
            value={filter}
          />
          <div className={styles.statusButtonsWrapper}>
            <div
              className={`${styles.statusButton} ${
                selectedFilter === 'All' ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => filterAndPlay()}
              ref={inputRef}
            >
              <TodayIcon sx={{ fontSize: 32 }} />
              <span className="ms-2">{qtdMovement}</span>
            </div>
            <div
              className={`${styles.statusButton} ${
                selectedFilter === '1' && filteringMove ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => filterReservations('move', '1')}
            >
              <KeyboardDoubleArrowDownIcon
                color="success"
                sx={{ fontSize: 52 }}
              />
              <span className="me-3">{qtdDown}</span>
            </div>
            <div
              className={`${styles.statusButton} ${
                selectedFilter === '5' ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => filterReservations('status', '5')}
            >
              <div
                className={`d-flex flex-column align-items-center justify-content-center ${styles.rotating}`}
              >
                <img
                  alt="Navegando"
                  src={Navegando}
                  className="jet me-3"
                  style={{ height: 32, width: 32 }}
                />
              </div>
              <span>{qtdNavigate}</span>
            </div>
            <div
              className={`${styles.statusButton} ${
                selectedFilter === '2' && filteringMove ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => filterReservations('move', '2')}
            >
              <KeyboardDoubleArrowUpIcon color="error" sx={{ fontSize: 52 }} />
              <span className="me-3">{qtdRise}</span>
            </div>
            <div
              className={`${styles.statusButton} ${
                selectedFilter === '2' && !filteringMove ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => filterReservations('status', '2')}
            >
              <img
                alt="Abastecimento"
                src={Abastecimento}
                className="jet me-2"
                style={{ height: 30, width: 30 }}
              />
              <span className="me-0">{qtfFueling}</span>
            </div>
            <div
              className={`${styles.statusButton} ${
                selectedFilter === '9' ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => filterReservations('status', '9')}
            >
              <HandymanIcon className="me-2" sx={{ fontSize: 32 }} />

              <span className="me-2">{qtfFueling}</span>
            </div>
            <div
              className={`${styles.statusButton} ${
                selectedFilter === '6' && filteringMove ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => filterReservations('move', '6')}
            >
              <SportsScoreIcon sx={{ fontSize: 32 }} />
              <span>{qtdFinished}</span>
            </div>
          </div>
          <div>
            <IconButton
              onClick={openSerialPort}
              size="small"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <UsbIcon
                color={port ? 'primary' : 'error'}
                sx={{ width: 32, height: 32 }}
              />
            </IconButton>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              {selectedSound === 'voice' && (
                <SpatialAudioOffIcon sx={{ width: 32, height: 32 }} />
              )}
              {selectedSound === 'bip' && (
                <MusicNoteIcon sx={{ width: 32, height: 32 }} />
              )}
              {selectedSound === 'muted' && (
                <VolumeOffIcon sx={{ width: 32, height: 32 }} />
              )}
            </IconButton>
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              disabled={selectedSound === 'voice'}
              onClick={() => selectSound('voice')}
            >
              <SpatialAudioOffIcon className="me-3" /> Voz
            </MenuItem>
            <MenuItem
              disabled={selectedSound === 'bip'}
              onClick={() => selectSound('bip')}
            >
              <MusicNoteIcon className="me-3" /> Bip
            </MenuItem>
            <MenuItem
              disabled={selectedSound === 'muted'}
              onClick={() => selectSound('muted')}
            >
              <VolumeOffIcon className="me-3" /> Sem som
            </MenuItem>
          </Menu>
        </div>
        <ThemeProvider theme={theme}>
          <div className={styles.grid}>
            <DataGrid
              sx={{ fontSize: 48 }}
              rows={reservations}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10
                  }
                }
              }}
              density="comfortable"
              pageSizeOptions={[10, 15, 25, 50, 100]}
              onCellClick={viewReservation}
              slots={{
                loadingOverlay: LinearProgress,
                // eslint-disable-next-line react/no-unstable-nested-components
                noRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Nenhuma Reserva
                  </Stack>
                )
              }}
              loading={loading}
              hideFooterPagination
            />
          </div>
        </ThemeProvider>
        <div className={styles.controls}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={`${styles.logoutButton} `}
            onClick={() => navigate('/marina/reservation/new')}
          >
            AGENDAMENTO
            <EditCalendarIcon className="ms-2" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={`${styles.logoutButton} `}
            onClick={() => navigate('/marina/hourly-occupation')}
          >
            OCUPAÇÃO HORÁRIA
            <QueryBuilderIcon className="ms-2" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={`${styles.logoutButton} ms-3`}
            onClick={() => navigate('/marina/reservation/individual')}
          >
            SAÍDA PONTUAL
            <FollowTheSignsOutlinedIcon className="ms-2" />
          </Button>
        </div>
      </div>
    </div>
  )
}
