import env from 'react-dotenv'
import { serviceNts } from '../ServiceNts.service'

const { API_APP } = env

const getById = async (movementId: number): Promise<any | Error> => {
  try {
    const dinamicUrl = `${API_APP}/movement/${movementId}`
    const movement = await serviceNts.get(dinamicUrl)
    return Promise.resolve(movement)
  } catch (error) {
    return Promise.reject(
      new Error(
        (error as { message: string }).message || 'Erro ao consultar o registro'
      )
    )
  }
}

const getByLocator = async (locator: string): Promise<any | Error> => {
  try {
    const dinamicUrl = `${API_APP}/movement/locator/${locator}`
    const movement = await serviceNts.get(dinamicUrl)
    return Promise.resolve(movement)
  } catch (error) {
    return Promise.reject(
      new Error(
        (error as { message: string }).message || 'Erro ao consultar o registro'
      )
    )
  }
}

const getAppointmentByDate = async (
  date: string,
  partnerId: number
): Promise<any | Error> => {
  try {
    const dinamicUrl = `${API_APP}/movement/appointment/${date}/partner/${partnerId}`
    const movement = await serviceNts.get(dinamicUrl)
    return Promise.resolve(movement)
  } catch (error) {
    return Promise.reject(
      new Error(
        (error as { message: string }).message || 'Erro ao consultar o registro'
      )
    )
  }
}

const updateCheckIn = async (
  locator: string,
  checkIn: string,
  registration: string
): Promise<any | Error> => {
  try {
    const dinamicUrl = `${API_APP}/movement/checkin/${locator}`
    await serviceNts.put(dinamicUrl, { checkIn, registration })
    return Promise.resolve(null)
  } catch (error) {
    return Promise.reject(
      new Error(
        (error as { message: string }).message || 'Erro ao atualizar o registro'
      )
    )
  }
}

/*
 * MOVE
 *         0: neutral
 *         1: desce requested
 *         2: sobe requested
 *         3: desce confirmed
 *         4: sobe confirmed
 *         5: desce finished
 *         6: sobe finished
 * STATUS
 *         1: 'GARAGE': 'Vessel at rest'
 *         2: 'CHECK': 'vessel check'
 *         3: 'START_ACTIVITY': 'Start activity'
 *         4: 'WATER': 'Vessel in the water'
 *         5: 'NAVIGATING': 'Vessel sailing'
 *         6: 'DISEMBARKATION': 'Vessel awaiting disembarkation'
 *         7: 'OVERDUE': 'delayed shipment'
 *         8: 'GATHERING': 'Collecting vessel'
 *         9: 'END_ACTIVITY': 'End activity'
 */
const updateMove = async (
  credential: string,
  locator: string,
  move: number,
  status: number
): Promise<any | Error> => {
  try {
    const dinamicUrl = `${API_APP}/movement/move/${locator}`
    await serviceNts.put(dinamicUrl, { credential, move, status })
    return Promise.resolve(null)
  } catch (error) {
    return Promise.reject(
      new Error(
        (error as { message: string }).message || 'Erro ao atualizar o registro'
      )
    )
  }
}

const createReserve = async (reserve: any): Promise<any | Error> => {
  try {
    const dinamicUrl = `${API_APP}/movement/reserve`
    const locator = await serviceNts.post(dinamicUrl, reserve)
    return Promise.resolve(locator)
  } catch (error) {
    return Promise.reject(
      new Error(
        (error as { message: string }).message || 'Erro ao incluir o registro'
      )
    )
  }
}
/*
const createDescent = async (descent: any): Promise<any | Error> => {
  try {
    const dinamicUrl = `${API_APP}/movement/descent`
    const locator = await serviceNts.post(dinamicUrl, descent)
    return Promise.resolve(locator)
  } catch (error) {
    return Promise.reject(
      new Error(
        (error as { message: string }).message || 'Erro ao incluir o registro'
      )
    )
  }
}

const createAscent = async (ascent: any): Promise<any | Error> => {
  try {
    const dinamicUrl = `${API_APP}/movement/ascent`
    const locator = await serviceNts.post(dinamicUrl, ascent)
    return Promise.resolve(locator)
  } catch (error) {
    return Promise.reject(
      new Error(
        (error as { message: string }).message || 'Erro ao incluir o registro'
      )
    )
  }
}
*/
const navigation = async (
  partnerId: number,
  dateMovement: string
): Promise<any | Error> => {
  try {
    const dinamicUrl = `${API_APP}/movement/schedule`
    const navigations = await serviceNts.post(dinamicUrl, {
      partnerId,
      dateMovement
    })
    return Promise.resolve(navigations)
  } catch (error) {
    return Promise.reject(
      new Error(
        (error as { message: string }).message || 'Erro ao consultar o registro'
      )
    )
  }
}

export const movementService = {
  getById,
  getByLocator,
  getAppointmentByDate,
  updateCheckIn,
  updateMove,
  createReserve,
  // createDescent,
  // createAscent,
  navigation
}
