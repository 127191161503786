import React, { useEffect, useState } from 'react'

import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
  TextField
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ptBR } from 'date-fns/locale'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import { useSnackbar } from 'notistack'
import { cpf } from 'cpf-cnpj-validator'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import styles from './SearchVesselModal.module.scss'
import TotemModal from '../../../../components/modal/Modal'
import {
  CellPhoneFormat,
  NumberFormatCPF,
  YearFormat,
  ZipCodeFormat
} from '../../../../components/number-format/NumberFormat'
import {
  getDocumentType,
  manufacturerOptions,
  relationshipOptions,
  states,
  validEmail,
  validPhone,
  vesselColorOptions,
  vesselTypeOptions
} from '../../../../utils/utils'
import { postOfficeService } from '../../../../services/PostOffice.service'
import {
  authService,
  memberService,
  vesselService
} from '../../../../services/ms-nts'
import { removeFormatDate, removeMask } from '../../../../utils/masks'
import { toInt } from '../../../../utils/auxiliary-functions'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

type ModalProps = {
  open: boolean
  handleClose: any
  height: any
  onSelectedVessel?: any
  onCancel?: any
  width: any
  isNewReservation?: boolean
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#3366FF'
    },
    secondary: {
      main: '#4E5F78'
    },
    mode: 'light'
  },

  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  }
})

const SearchVesselModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  height,
  onSelectedVessel,
  onCancel,
  isNewReservation,
  width
}: ModalProps) => {
  const [searching, setSearching] = useState(false)
  const [registering, setRegistering] = useState(false)
  const [isNewCustomer, setIsnewCustomer] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [foundVessels, setFoundVessels] = useState<Array<any>>([])
  const [selectedVesselForm, setSelectedVesselForm] = useState({
    passengers: '',
    navigationPlan: '',
    sailorRequested: false
  })
  const [newCustomerForm, setNewCustomerForm] = useState({
    birthDate: new Date(),
    phone: '',
    email: '',
    nauticalLicense: '',
    nauticalLicenseExpiration: new Date(),
    address: {
      zipCode: '',
      street: '',
      number: '',
      district: '',
      city: '',
      suite: '',
      state: ''
    },
    vesselId: '',
    vesselName: '',
    vesselEnrollment: '',
    vesselType: '',
    vesselManufacturer: '',
    vesselBrand: '',
    vesselImage: '',
    vesselIndAlert: '',
    vesselIndRestriction: '',
    vesselModel: '',
    vesselYear: '',
    vesselColor: '',
    ownerId: '',
    ownerName: '',
    ownerRegistration: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    emergencyContactRelationship: '',
    partnerId: '',
    partnerName: ''
  })
  const [selectedVessel, setSelectedVessel] = useState<any>()
  const [value, setValue] = React.useState(0)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (newCustomerForm.address.zipCode.length === 8) {
      searchZipCode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCustomerForm.address.zipCode])

  const searchZipCode = async () => {
    const address = await postOfficeService.searchZipCode(
      newCustomerForm.address.zipCode
    )

    setNewCustomerForm({
      ...newCustomerForm,
      address: {
        ...newCustomerForm.address,
        street: address.logradouro,
        number: '',
        district: address.bairro,
        city: address.localidade,
        suite: '',
        state: states.find(state => state.value === address.uf)?.label || ''
      }
    })
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const cancel = () => {
    setIsnewCustomer(false)
    setSearchTerm('')
    setFoundVessels([])
    setSelectedVessel({
      passengers: '',
      navigationPlan: '',
      sailorRequested: false
    })
    onCancel()
  }

  const cancelNewRegistration = () => {
    setIsnewCustomer(false)
    setNewCustomerForm({
      birthDate: new Date(),
      phone: '',
      email: '',
      nauticalLicense: '',
      nauticalLicenseExpiration: new Date(),
      address: {
        zipCode: '',
        street: '',
        number: '',
        district: '',
        city: '',
        suite: '',
        state: ''
      },
      vesselId: '',
      vesselName: '',
      vesselEnrollment: '',
      vesselType: '',
      vesselManufacturer: '',
      vesselBrand: '',
      vesselImage: '',
      vesselIndAlert: '',
      vesselIndRestriction: '',
      vesselModel: '',
      vesselYear: '',
      vesselColor: '',
      ownerId: '',
      ownerName: '',
      ownerRegistration: '',
      emergencyContactName: '',
      emergencyContactPhone: '',
      emergencyContactRelationship: '',
      partnerId: '',
      partnerName: ''
    })
  }

  const searchVessel = async () => {
    setSearching(true)
    try {
      const vessels = await vesselService.getByDocument(searchTerm.trim())
      if (vessels) {
        setFoundVessels(vessels)
      } else {
        enqueueSnackbar('Não encontrado embarcações', { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(
        (error as { message: string }).message ||
          'Ocorreu um problema, tente novamente',
        {
          variant: 'error'
        }
      )
    } finally {
      setSearching(false)
    }
  }

  const undoSearch = () => {
    setFoundVessels([])
    setSelectedVessel([])
  }

  const proceed = () => {
    const individualVessel = {
      passengers: selectedVesselForm.passengers,
      navigationPlan: selectedVesselForm.navigationPlan,
      sailorRequested: selectedVesselForm.sailorRequested,
      vesselId: selectedVessel.vesselId,
      vesselName: selectedVessel.vesselName,
      vesselEnrollment: selectedVessel.vesselEnrollment,
      vesselType: selectedVessel.vesselType,
      vesselModel: selectedVessel.vesselModel,
      vesselBrand: selectedVessel.vesselBrand,
      vesselImage: selectedVessel.vesselImage,
      vesselIndAlert: selectedVessel.vesselIndAlert,
      vesselIndRestriction: selectedVessel.vesselIndRestriction,
      ownerCheckIn: {
        id: selectedVessel.ownerId,
        name: selectedVessel.ownerName,
        registration: selectedVessel.ownerRegistration,
        contactName: selectedVessel.emergencyContactName,
        contactPhone: selectedVessel.emergencyContactPhone
      },
      partnerId: selectedVessel.partnerId,
      partnerName: selectedVessel.partnerName
    }
    onSelectedVessel(individualVessel)
    undoSearch()
  }

  const handleKeypress = (e: any) => {
    if (e.charCode === 13) {
      proceed()
    }
  }

  const handleKeypressSearch = (e: any) => {
    if (e.charCode === 13) {
      searchVessel()
    }
  }

  const isValidDocument = (): boolean => {
    if (
      getDocumentType(newCustomerForm?.ownerRegistration) === 'CPF' &&
      cpf.isValid(newCustomerForm?.ownerRegistration)
    ) {
      return true
    }
    return false
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
        maxWidth: 250
      }
    }
  }

  const registerNewCustomer = () => {
    setIsnewCustomer(true)
    setValue(0)
    setSearchTerm('')
    setFoundVessels([])
    setSelectedVessel({
      passengers: '',
      navigationPlan: '',
      sailorRequested: false
    })
  }

  const fillNonCustomer = () => {
    const partner = authService.currentPartner()

    const adresses = []
    adresses[0] = {
      address: {
        zipcode: removeMask(newCustomerForm.address.zipCode),
        state: newCustomerForm.address.state,
        city: newCustomerForm.address.city,
        district: newCustomerForm.address.district,
        place: newCustomerForm.address.street
      },
      habitation: newCustomerForm.address.number,
      complement: newCustomerForm.address.suite
    }

    const phones = []
    phones[0] = {
      connection: removeMask(newCustomerForm.phone)
    }

    const documentsNautical = []
    documentsNautical[0] = {
      enrollment: removeMask(newCustomerForm.nauticalLicense),
      validity: removeFormatDate(
        newCustomerForm.nauticalLicenseExpiration.toLocaleDateString()
      )
    }

    const dataMember = {
      people: {
        fullname: newCustomerForm.ownerName,
        birth: removeFormatDate(newCustomerForm.birthDate.toLocaleDateString()),
        email: newCustomerForm.email,
        phones,
        documentsNautical,
        registrationPf: removeMask(newCustomerForm.ownerRegistration)
      }
    }

    const dataResponsible = {
      ownerType: 'PF',
      owner: dataMember.people,
      principal: 1,
      contactName: newCustomerForm.emergencyContactName,
      contactPhone: removeMask(newCustomerForm.emergencyContactPhone),
      contactRelationship: newCustomerForm.emergencyContactRelationship
    }

    const dataVessel = {
      name: newCustomerForm.vesselName,
      year: newCustomerForm.vesselYear,
      typeVessel: newCustomerForm.vesselType,
      enrollment: newCustomerForm.vesselEnrollment,
      manufacturer: newCustomerForm.vesselManufacturer,
      color: newCustomerForm.vesselColor,
      brand: newCustomerForm.vesselBrand,
      image: newCustomerForm.vesselImage,
      indAlert: newCustomerForm.vesselIndAlert,
      indRestriction: newCustomerForm.vesselIndRestriction,
      model: newCustomerForm.vesselModel,
      responsible: dataResponsible
    }

    const dataCustomer = {
      partnerId: partner.id,
      member: dataMember,
      vessel: dataVessel
    }

    return dataCustomer
  } // fillNonCustomer

  const register = () => {
    setRegistering(true)

    const nonCustomer = fillNonCustomer()
    memberService.createNonCustomer(nonCustomer).then((result: any) => {
      const vessel = {
        vesselId: result.vesselId,
        vesselName: result.vesselName || '-',
        vesselEnrollment: result.vesselEnrollment,
        vesselType: vesselTypeOptions.find(
          (type: any) => type.id === toInt(result.vesselType)
        )?.id,
        vesselModel: result.vesselModel,
        vesselBrand: result.vesselBrand,
        vesselImage: result.vesselImage,
        vesselIndAlert: result.vesselIndAlert,
        vesselIndRestriction: result.vesselIndRestriction,
        ownerId: result.ownerId,
        ownerName: result.ownerName,
        ownerRegistration: result.ownerRegistration,
        emergencyContactName: result.emergencyContactName,
        emergencyContactPhone: result.emergencyContactPhone,
        partnerId: result.partnerId,
        partnerName: result.partnerName
      }
      setIsnewCustomer(false)
      setSelectedVessel(vessel)
      setFoundVessels([vessel])
    })
    setRegistering(false)
  }

  const isValid = () => {
    const today = new Date()
    if (
      cpf.isValid(newCustomerForm?.ownerRegistration) &&
      newCustomerForm.ownerName !== '' &&
      newCustomerForm.phone.match(validPhone) &&
      newCustomerForm.email.match(validEmail) &&
      newCustomerForm.nauticalLicense.length > 12 &&
      new Date(newCustomerForm.nauticalLicenseExpiration) > today &&
      newCustomerForm.emergencyContactName !== '' &&
      newCustomerForm.emergencyContactRelationship !== '' &&
      newCustomerForm.emergencyContactPhone.match(validPhone) &&
      newCustomerForm.vesselType &&
      newCustomerForm.vesselManufacturer &&
      newCustomerForm.vesselEnrollment &&
      newCustomerForm.vesselModel &&
      newCustomerForm.vesselYear.length === 4 &&
      newCustomerForm.vesselColor
    ) {
      return true
    }
    return false
  }

  const handleClickSelectedVessel = (e: any, vessel: any) => {
    setSelectedVessel({
      vesselId: vessel.id,
      vesselName: vessel.name,
      vesselEnrollment: vessel.enrollment,
      vesselType: vessel.type,
      vesselModel: vessel.model,
      vesselBrand: vessel.brand,
      vesselImage: vessel.image,
      vesselIndAlert: vessel.indAlert,
      vesselIndRestriction: vessel.indRestriction,
      passengers: 0,
      navigationPlan: '',
      sailorRequested: false,
      ownerId: vessel.owner.id,
      ownerName: vessel.owner.name,
      ownerRegistration: vessel.owner.registration,
      emergencyContactName: vessel.contactName,
      emergencyContactPhone: vessel.contactPhone,
      partnerId: vessel.partnerId,
      partnerName: vessel.partnerName
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <TotemModal
        open={open}
        name={
          isNewCustomer
            ? 'Cadastro não cliente'
            : selectedVessel
            ? selectedVessel.vesselName
            : 'Buscar Embarcação'
        }
        handleClose={handleClose}
        noClose
        width={
          isNewCustomer
            ? '100vw'
            : foundVessels.length > 0
            ? width + 190
            : width
        }
        height={isNewCustomer ? '60vh' : height}
      >
        <div className="d-flex align-items-center flex-column">
          {!selectedVessel?.vesselName && !isNewCustomer && (
            <>
              <h6 className="gray-text text-center">
                {foundVessels.length
                  ? 'Embarcações encontradas:'
                  : 'Digite o CPF do responsável ou ID da embarcação'}
              </h6>
              {!foundVessels.length && (
                <TextField
                  margin="normal"
                  name="vessel"
                  type="number"
                  id="vessel"
                  autoFocus
                  variant="outlined"
                  autoComplete="off"
                  onChange={e => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  label="Buscar"
                  disabled={searching}
                  onKeyPress={handleKeypressSearch}
                  color="primary"
                  className="mt-3 mb-0"
                  inputProps={{
                    maxLength: 11
                  }}
                  sx={{ minWidth: 325, maxWidth: 325 }}
                />
              )}
              {foundVessels.map((vessel: any) => (
                <div
                  key={vessel.id}
                  className={`d-flex justify-content-between w-100 mt-2 ${styles.foundVessels}`}
                  role="presentation"
                  onClick={e => handleClickSelectedVessel(e, vessel)}
                >
                  <span>{vessel.enrollment}</span>
                  <span>{vessel.name}</span>
                  <span>{vessel.type === '33' ? 'Jet' : 'Lancha'}</span>
                  <span>{vessel.owner.name}</span>
                </div>
              ))}
              <div className="d-flex flex-column align-items-center">
                {!foundVessels.length && (
                  <div className="mt-4 justify-content-center d-flex">
                    <Button
                      variant="outlined"
                      onClick={() => cancel()}
                      color="secondary"
                      className={`${styles.submit} me-4`}
                      disabled={searching}
                      size="large"
                    >
                      Cancelar
                    </Button>
                    <Button
                      disabled={searching || searchTerm.length < 4}
                      variant="outlined"
                      color="primary"
                      size="large"
                      className={`${styles.submit}`}
                      onClick={() => searchVessel()}
                    >
                      Buscar
                    </Button>
                  </div>
                )}
                {foundVessels.length > 0 && (
                  <Button
                    disabled={searching}
                    variant="outlined"
                    sx={{ width: 245 }}
                    color="primary"
                    size="large"
                    className={`${styles.submit} mt-3`}
                    onClick={() => undoSearch()}
                  >
                    Refazer busca
                  </Button>
                )}
                {!isNewReservation && !foundVessels.length && (
                  <Button
                    disabled={searching}
                    variant="contained"
                    sx={{ width: 245 }}
                    color="primary"
                    size="large"
                    className={`${styles.submit} mt-3`}
                    onClick={() => registerNewCustomer()}
                  >
                    Não cliente
                  </Button>
                )}
              </div>
            </>
          )}
          {isNewCustomer && (
            <>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                  >
                    <Tab label="Responsável" {...a11yProps(0)} />
                    <Tab label="Endereço" {...a11yProps(1)} />
                    <Tab label="Embarcação" {...a11yProps(2)} />
                    <Tab label="Contato de Emergência" {...a11yProps(3)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <section className={styles.section}>
                    <div className="d-flex justify-content-between">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="CPF*"
                        autoComplete="off"
                        name="CPF"
                        type="text"
                        autoFocus
                        id="CPF"
                        fullWidth
                        value={newCustomerForm?.ownerRegistration || ''}
                        helperText={
                          getDocumentType(
                            newCustomerForm?.ownerRegistration
                          ) === 'CPF' &&
                          !cpf.isValid(newCustomerForm?.ownerRegistration)
                            ? `Digite um CPF válido`
                            : ''
                        }
                        error={
                          newCustomerForm?.ownerRegistration?.length === 11 &&
                          !isValidDocument()
                        }
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            ownerRegistration: e.target.value
                          })
                        }
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3 me-3"
                        sx={{ minWidth: 180, maxWidth: 180 }}
                        InputProps={{
                          inputComponent: NumberFormatCPF as any
                        }}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Nome completo*"
                        autoComplete="off"
                        name="name"
                        type="text"
                        id="name"
                        fullWidth
                        value={newCustomerForm?.ownerName || ''}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            ownerName: e.target.value
                          })
                        }
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3 me-3"
                        sx={{ minWidth: 375, maxWidth: 375 }}
                        inputProps={{
                          maxLength: 60
                        }}
                      />
                      <LocalizationProvider
                        adapterLocale={ptBR}
                        dateAdapter={AdapterDateFns}
                      >
                        <DesktopDatePicker
                          label="Data de Nascimento*"
                          sx={{ width: 180 }}
                          value={newCustomerForm.birthDate}
                          onChange={e =>
                            setNewCustomerForm({
                              ...newCustomerForm,
                              birthDate: e ? new Date(e) : new Date()
                            })
                          }
                          disabled={registering}
                        />
                      </LocalizationProvider>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="cell_phone"
                        label="Telefone*"
                        name="cell_phone"
                        type="text"
                        autoComplete="off"
                        sx={{ minWidth: 180, maxWidth: 180 }}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            phone: e.target.value
                          })
                        }
                        error={
                          newCustomerForm.phone.length === 11
                            ? !newCustomerForm.phone.match(validPhone)
                            : false
                        }
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3 ms-3"
                        InputProps={{
                          inputComponent: CellPhoneFormat as any
                        }}
                        value={newCustomerForm.phone || ''}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="email"
                        label="E-mail *"
                        name="email"
                        type="text"
                        autoComplete="off"
                        sx={{ minWidth: 375, maxWidth: 375 }}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            email: e.target.value
                          })
                        }
                        inputProps={{
                          maxLength: 50
                        }}
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3 me-4"
                        error={
                          newCustomerForm?.email
                            ? !newCustomerForm?.email?.match(validEmail)
                            : false
                        }
                        value={newCustomerForm?.email || ''}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Habilitação náutica*"
                        autoComplete="off"
                        name="nauticalLicense"
                        type="text"
                        id="nauticalLicense"
                        fullWidth
                        value={newCustomerForm?.nauticalLicense || ''}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            nauticalLicense: e.target.value.toUpperCase()
                          })
                        }
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3 me-4"
                        sx={{ minWidth: 360, maxWidth: 360 }}
                        inputProps={{
                          maxLength: 16
                        }}
                      />
                      <LocalizationProvider
                        adapterLocale={ptBR}
                        dateAdapter={AdapterDateFns}
                      >
                        <DesktopDatePicker
                          label="Data de Validade*"
                          sx={{ width: 180 }}
                          value={
                            newCustomerForm.nauticalLicenseExpiration || ''
                          }
                          minDate={new Date()}
                          onChange={e =>
                            setNewCustomerForm({
                              ...newCustomerForm,
                              nauticalLicenseExpiration: e
                                ? new Date(e)
                                : new Date()
                            })
                          }
                          disabled={registering}
                        />
                      </LocalizationProvider>
                    </div>
                  </section>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <section className={styles.section}>
                    <div className="d-flex justify-content-between">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="cep"
                        label="CEP"
                        name="cep"
                        autoFocus
                        type="text"
                        autoComplete="off"
                        sx={{ minWidth: 120, maxWidth: 120 }}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            address: {
                              ...newCustomerForm.address,
                              zipCode: e.target.value
                            }
                          })
                        }
                        InputProps={{
                          inputComponent: ZipCodeFormat as any
                        }}
                        value={newCustomerForm.address.zipCode || ''}
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3 me-3"
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Logradouro"
                        autoComplete="off"
                        name="street"
                        type="text"
                        id="street"
                        fullWidth
                        value={newCustomerForm?.address.street || ''}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            address: {
                              ...newCustomerForm.address,
                              street: e.target.value
                            }
                          })
                        }
                        disabled
                        color="primary"
                        className="mt-0 mb-3 me-3"
                        sx={{ minWidth: 360, maxWidth: 360 }}
                        inputProps={{
                          maxLength: 60
                        }}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="number"
                        label="Número"
                        name="number"
                        type="text"
                        autoComplete="off"
                        sx={{ minWidth: 150, maxWidth: 150 }}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            address: {
                              ...newCustomerForm.address,
                              number: e.target.value
                            }
                          })
                        }
                        value={newCustomerForm.address.number || ''}
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3 me-3"
                        inputProps={{
                          maxLength: 6
                        }}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="suite"
                        label="Complemento"
                        name="suite"
                        type="text"
                        autoComplete="off"
                        sx={{ minWidth: 285, maxWidth: 285 }}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            address: {
                              ...newCustomerForm.address,
                              suite: e.target.value
                            }
                          })
                        }
                        value={newCustomerForm.address.suite || ''}
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3"
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="district"
                        label="Bairro"
                        name="district"
                        type="text"
                        autoComplete="off"
                        sx={{ minWidth: 310, maxWidth: 310 }}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            address: {
                              ...newCustomerForm.address,
                              district: e.target.value
                            }
                          })
                        }
                        value={newCustomerForm.address.district || ''}
                        disabled
                        color="primary"
                        className="mt-0 mb-3 me-3"
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="city"
                        label="Cidade"
                        name="city"
                        type="text"
                        autoComplete="off"
                        sx={{ minWidth: 310, maxWidth: 310 }}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            address: {
                              ...newCustomerForm.address,
                              city: e.target.value
                            }
                          })
                        }
                        value={newCustomerForm.address.city || ''}
                        disabled
                        color="primary"
                        className="mt-0 mb-3 me-3"
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="state"
                        label="Estado"
                        name="state"
                        type="text"
                        autoComplete="off"
                        sx={{ minWidth: 310, maxWidth: 310 }}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            address: {
                              ...newCustomerForm.address,
                              state: e.target.value
                            }
                          })
                        }
                        value={newCustomerForm.address.state || ''}
                        disabled
                        color="primary"
                        className="mt-0 mb-3 me-3"
                      />
                    </div>
                  </section>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <section className={styles.section}>
                    <div className="d-flex justify-content-between">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Nome*"
                        autoComplete="off"
                        name="name"
                        type="text"
                        id="name"
                        fullWidth
                        autoFocus
                        value={newCustomerForm?.emergencyContactName || ''}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            emergencyContactName: e.target.value
                          })
                        }
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3 me-3"
                        sx={{ minWidth: 375, maxWidth: 375 }}
                        inputProps={{
                          maxLength: 60
                        }}
                      />
                      <FormControl
                        variant="outlined"
                        sx={{
                          minWidth: 375,
                          maxWidth: 375
                        }}
                      >
                        <InputLabel id="relationship">Relação *</InputLabel>
                        <Select
                          labelId="relationship"
                          id="relationship-select"
                          value={
                            newCustomerForm.emergencyContactRelationship || ''
                          }
                          onChange={e =>
                            setNewCustomerForm({
                              ...newCustomerForm,
                              emergencyContactRelationship: e.target.value
                            })
                          }
                          label="Relação *"
                          MenuProps={MenuProps}
                        >
                          {relationshipOptions?.map(
                            (
                              option: any,
                              index: {
                                toString: () => React.Key | null | undefined
                              }
                            ) => (
                              <MenuItem
                                selected={
                                  newCustomerForm.emergencyContactRelationship?.toLowerCase() ===
                                  option.value
                                }
                                key={index.toString()}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="emergencyContactPhone"
                        label="Telefone*"
                        name="emergencyContactPhone"
                        type="text"
                        autoComplete="off"
                        sx={{ minWidth: 180, maxWidth: 180 }}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            emergencyContactPhone: e.target.value
                          })
                        }
                        error={
                          newCustomerForm.emergencyContactPhone.length === 11
                            ? !newCustomerForm.emergencyContactPhone.match(
                                validPhone
                              )
                            : false
                        }
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3 ms-3"
                        InputProps={{
                          inputComponent: CellPhoneFormat as any
                        }}
                        value={newCustomerForm.emergencyContactPhone || ''}
                      />
                    </div>
                  </section>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <section className={styles.section}>
                    <div className="d-flex justify-content-between">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Nome"
                        autoComplete="off"
                        name="name"
                        type="text"
                        id="name"
                        fullWidth
                        autoFocus
                        value={newCustomerForm?.vesselName || ''}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            vesselName: e.target.value
                          })
                        }
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3"
                        sx={{ minWidth: 415, maxWidth: 415 }}
                        inputProps={{
                          maxLength: 60
                        }}
                      />
                      <FormControl
                        variant="outlined"
                        sx={{
                          minWidth: 150,
                          maxWidth: 150
                        }}
                      >
                        <InputLabel id="vessel-type">Tipo *</InputLabel>
                        <Select
                          labelId="vessel-type"
                          id="vessel-type-select"
                          value={newCustomerForm.vesselType || ''}
                          onChange={e =>
                            setNewCustomerForm({
                              ...newCustomerForm,
                              vesselType: e.target.value
                            })
                          }
                          label="Tipo *"
                          MenuProps={MenuProps}
                        >
                          {vesselTypeOptions?.map(
                            (
                              option: any,
                              index: {
                                toString: () => React.Key | null | undefined
                              }
                            ) => (
                              <MenuItem
                                selected={
                                  newCustomerForm.vesselType === option.id
                                }
                                key={index.toString()}
                                value={option.id || ''}
                              >
                                {option.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Registro*"
                        autoComplete="off"
                        name="vesselEnrollment"
                        type="text"
                        id="vesselEnrollment"
                        fullWidth
                        value={newCustomerForm?.vesselEnrollment || ''}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            vesselEnrollment: e.target.value
                          })
                        }
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3"
                        sx={{ minWidth: 180, maxWidth: 180 }}
                        inputProps={{
                          maxLength: 16
                        }}
                      />
                      <FormControl
                        variant="outlined"
                        sx={{
                          minWidth: 150,
                          maxWidth: 150
                        }}
                      >
                        <InputLabel id="vessel-color">Cor*</InputLabel>
                        <Select
                          labelId="vessel-color"
                          id="vessel-color-select"
                          value={newCustomerForm.vesselColor || ''}
                          onChange={e =>
                            setNewCustomerForm({
                              ...newCustomerForm,
                              vesselColor: e.target.value
                            })
                          }
                          label="Cor*"
                          MenuProps={MenuProps}
                        >
                          {vesselColorOptions?.map(
                            (
                              option: any,
                              index: {
                                toString: () => React.Key | null | undefined
                              }
                            ) => (
                              <MenuItem
                                selected={
                                  newCustomerForm.vesselColor?.toLowerCase() ===
                                  option.value
                                }
                                key={index.toString()}
                                value={option.value || ''}
                              >
                                {option.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="d-flex justify-content-between">
                      <FormControl
                        variant="outlined"
                        sx={{
                          minWidth: 260,
                          maxWidth: 260
                        }}
                      >
                        <InputLabel id="vessel-manufacturer">
                          Fabricante*
                        </InputLabel>
                        <Select
                          labelId="vessel-manufacturer"
                          id="vessel-manufacturer-select"
                          value={newCustomerForm.vesselManufacturer || ''}
                          onChange={e =>
                            setNewCustomerForm({
                              ...newCustomerForm,
                              vesselManufacturer: e.target.value,
                              vesselBrand: e.target.value
                            })
                          }
                          label="Fabricante*"
                          MenuProps={MenuProps}
                        >
                          {manufacturerOptions?.map(
                            (
                              option: any,
                              index: {
                                toString: () => React.Key | null | undefined
                              }
                            ) => (
                              <MenuItem
                                selected={
                                  newCustomerForm.vesselManufacturer?.toLowerCase() ===
                                  option.value
                                }
                                key={index.toString()}
                                value={option.value || ''}
                              >
                                {option.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        sx={{
                          minWidth: 260,
                          maxWidth: 260
                        }}
                      >
                        <InputLabel id="vessel-brand">Marca</InputLabel>
                        <Select
                          labelId="vessel-brand"
                          id="vessel-brand-select"
                          value={newCustomerForm.vesselBrand || ''}
                          onChange={e =>
                            setNewCustomerForm({
                              ...newCustomerForm,
                              vesselBrand: e.target.value
                            })
                          }
                          disabled
                          label="Marca"
                          MenuProps={MenuProps}
                        >
                          {manufacturerOptions?.map(
                            (
                              option: any,
                              index: {
                                toString: () => React.Key | null | undefined
                              }
                            ) => (
                              <MenuItem
                                selected={
                                  newCustomerForm.vesselBrand?.toLowerCase() ===
                                  option.value
                                }
                                key={index.toString()}
                                value={option.value || ''}
                              >
                                {option.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Modelo*"
                        autoComplete="off"
                        name="vesselModel"
                        type="text"
                        id="vesselModel"
                        fullWidth
                        value={newCustomerForm?.vesselModel || ''}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            vesselModel: e.target.value
                          })
                        }
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3"
                        sx={{ minWidth: 250, maxWidth: 250 }}
                        inputProps={{
                          maxLength: 60
                        }}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        label="Ano*"
                        autoComplete="off"
                        name="vesselYear"
                        type="text"
                        id="vesselYear"
                        fullWidth
                        value={newCustomerForm?.vesselYear || ''}
                        onChange={e =>
                          setNewCustomerForm({
                            ...newCustomerForm,
                            vesselYear: e.target.value
                          })
                        }
                        disabled={registering}
                        color="primary"
                        className="mt-0 mb-3"
                        sx={{ minWidth: 120, maxWidth: 120 }}
                        InputProps={{
                          inputComponent: YearFormat as any
                        }}
                      />
                    </div>
                  </section>
                </TabPanel>
              </Box>
              <div
                className={`d-flex ${
                  value === 3 ? styles.emergencyContact : ''
                }`}
              >
                <Button
                  disabled={searching}
                  variant="outlined"
                  color="inherit"
                  size="large"
                  className={`${styles.submit} mt-3 me-3`}
                  onClick={() => cancelNewRegistration()}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={searching || !isValid()}
                  variant="contained"
                  color="primary"
                  size="large"
                  className={`${styles.submit} mt-3`}
                  onClick={() => register()}
                >
                  Cadastrar cliente
                </Button>
              </div>
            </>
          )}
          {selectedVessel?.vesselName && (
            <>
              <h6>Preencha o Plano de Navegação</h6>
              <div className="d-flex">
                <div>
                  <h6 className="mt-3">Plano de Navegação</h6>
                  <TextareaAutosize
                    value={selectedVesselForm.navigationPlan || ''}
                    minRows={3}
                    color="primary"
                    style={{
                      maxHeight: 70,
                      minWidth: 560,
                      minHeight: 65,
                      maxWidth: 560,
                      borderRadius: 6,
                      backgroundColor: '#FFFFFF',
                      padding: 10,
                      color: 'black'
                    }}
                    placeholder="Digite o seu plano de navegação (não obrigatório para moto aquática)"
                    onChange={e =>
                      setSelectedVesselForm({
                        ...selectedVesselForm,
                        navigationPlan: e.target.value
                      })
                    }
                  />
                </div>
              </div>
              <div className="d-flex mb-3 align-items-baseline w-100">
                <div className="d-flex align-items-baseline">
                  <h6 className="mt-2">Informar passageiros</h6>
                  <TextField
                    margin="normal"
                    name="passengers"
                    type="number"
                    id="passengers"
                    onKeyPress={handleKeypress}
                    autoFocus
                    variant="standard"
                    autoComplete="off"
                    onChange={e =>
                      setSelectedVesselForm({
                        ...selectedVesselForm,
                        passengers: e.target.value
                      })
                    }
                    value={selectedVesselForm.passengers || ''}
                    label="Opcional"
                    disabled={searching}
                    color="primary"
                    className="mt-2 ms-4 mb-0"
                    sx={{ minWidth: 120, maxWidth: 120 }}
                  />
                </div>
                <FormControlLabel
                  className="ms-4 me-0"
                  control={
                    <Switch
                      checked={selectedVesselForm.sailorRequested}
                      onChange={e =>
                        setSelectedVesselForm({
                          ...selectedVesselForm,
                          sailorRequested: e.target.checked
                        })
                      }
                    />
                  }
                  label="Disponibilizar Marinheiro"
                />
              </div>
              <div className="d-flex">
                <Button
                  disabled={searching}
                  variant="outlined"
                  color="inherit"
                  size="large"
                  className={`${styles.submit} mt-3 me-3`}
                  onClick={() => setSelectedVessel([])}
                >
                  Voltar
                </Button>
                <Button
                  disabled={
                    searching ||
                    (selectedVessel.vesselType === '34' &&
                      selectedVesselForm.navigationPlan.length < 20)
                  }
                  variant="contained"
                  color="primary"
                  size="large"
                  className={`${styles.submit} mt-3`}
                  onClick={() => proceed()}
                >
                  Prosseguir
                </Button>
              </div>
            </>
          )}
        </div>
      </TotemModal>
    </ThemeProvider>
  )
}

SearchVesselModal.defaultProps = {
  onCancel: false
}

export default SearchVesselModal
