import env from 'react-dotenv'
import { serviceNts } from '../ServiceNts.service'

const { API_APP } = env

const createNonCustomer = async (customer: any): Promise<any | Error> => {
  try {
    const dinamicUrl = `${API_APP}/member/non-customer`
    const locator = await serviceNts.post(dinamicUrl, customer)
    return Promise.resolve(locator)
  } catch (error) {
    return Promise.reject(
      new Error(
        (error as { message: string }).message || 'Erro ao incluir o registro'
      )
    )
  }
}

export const memberService = {
  createNonCustomer
}
