import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import LogoutIcon from '@mui/icons-material/Logout'
import { Badge, Box, IconButton, Menu, MenuItem } from '@mui/material'
import styles from './Header.module.scss'
// import defaultAvatar from '../../../assets/images/profile.jpg'
import Logo from '../../../assets/images/N.png'
import { authService } from '../../services/ms-nts'

type HeaderProps = {
  title?: string
}
const Header: React.FC<HeaderProps> = ({ title }: HeaderProps) => {
  const [partner, setPartner] = useState({} as any)
  const [time, setTime] = useState(new Date())
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null)

  const navigate = useNavigate()

  const open = Boolean(anchorEl)
  const openSettings = Boolean(anchorElSettings)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickSettings = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSettings(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setAnchorElSettings(null)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date())
    }, 1000)
    const p = authService.currentPartner()
    setPartner(p)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <section className={styles.header}>
      <div className={styles.time}>
        <img src={Logo} alt="Avatar" className={styles.logo} />
        <h5 className="text-center mt-2" style={{ textTransform: 'uppercase' }}>
          {title}
        </h5>
      </div>
      <div className="me-5" />
      <div
        className={`d-flex flex-column align-items-center ${styles.marinaName}`}
      >
        <h3>{partner?.company?.fantasy}</h3>
        <h5>{`${time.toLocaleTimeString()}`}</h5>
      </div>
      <div className={styles.user}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        >
          <h6>{`${new Date().toLocaleDateString()}`}</h6>

          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge badgeContent={0} color="error">
              <NotificationsNoneIcon
                fontSize="large"
                style={{ color: 'rgba(255, 255, 255, 0.75)' }}
              />
            </Badge>
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleClose}>Sem notificações</MenuItem>
        </Menu>

        <IconButton
          onClick={handleClickSettings}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={openSettings ? 'account-menu-settings' : undefined}
          aria-haspopup="true"
          aria-expanded={openSettings ? 'true' : undefined}
        >
          {/* <img src={defaultAvatar} alt="Avatar" className="avatar ms-2" /> */}
          <div className="avatar-placeholder ms-2">
            <img
              className={styles.avatar}
              src={partner?.company?.image}
              alt=""
            />
          </div>
        </IconButton>
        <Menu
          anchorEl={anchorElSettings}
          id="account-menu-settings"
          open={openSettings}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem role="presentation" onClick={() => navigate('/')}>
            SAIR
            <LogoutIcon className="ms-2" />
          </MenuItem>
        </Menu>
      </div>
    </section>
  )
}

export default Header
