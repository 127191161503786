import axios, { AxiosError, AxiosResponse } from 'axios'
import { responseInterceptor } from './ResponseInterceptor'
import { errorInterceptor } from './ErrorInterceptor'

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
})

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => responseInterceptor(response),
  (error: AxiosError) => errorInterceptor(error)
)

/*
  response => response,
  error => {
    if (error.response?.status === 401) {
      window.location.href = '/login'
    }
  }
*/

const api = {
  get(url: any, params?: any) {
    const config = {} as any
    if (params && typeof params === 'object') {
      const headers = {} as any
      const others = {} as any
      const keys = Object.keys(params)
      keys.forEach((keyName: string) => {
        if (keyName === 'content-type') {
          headers[keyName] = params[keyName]
        } else if (keyName === 'x-access-token') {
          headers[keyName] = params[keyName]
        } else {
          others[keyName] = params[keyName]
        }
      })
      config.headers = headers
      config.params = others
    }
    return axiosInstance.get(url, config)
  },

  post(
    url: string,
    data?: any,
    headers = { 'content-type': 'application/json' }
  ) {
    return axiosInstance.post(url, data, { headers })
  },

  put(
    url: string,
    data: any,
    headers = { 'content-type': 'application/json' }
  ) {
    return axiosInstance.put(url, data, { headers })
  },

  delete(url: string, params?: any) {
    return axiosInstance.delete(url, { params })
  }
}

export default api
