import api from '../api/api'

const searchZipCode = async (zipCode: string) => {
  try {
    const response = await api.get(`https://viacep.com.br/ws/${zipCode}/json`)

    return response.data
  } catch (error: any) {
    return error.response.data.message
  }
}

export const postOfficeService = {
  searchZipCode
}
