import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { Button, TextField } from '@mui/material'
import EditCalendarIcon from '@mui/icons-material/EditCalendar'
import HouseboatIcon from '@mui/icons-material/Houseboat'

import { useSnackbar } from 'notistack'
import NautiSystem from '../../../assets/images/logoWithR.png'
import styles from './Totem.module.scss'
import MarinaLoginModal from '../marina/components/marina-login-modal/MarinaLoginModal'
import SearchVesselModal from '../reservation/components/search-vessel-modal/SearchVesselModal'
import { movementService } from '../../services/ms-nts/Movement.service'
import { isEmpty } from '../../utils/validators'
import { dateAdd, dateToString } from '../../utils/auxiliary-functions'

const Totem: React.FC = () => {
  const [reservation, setReservation] = useState<string>('')
  const [searching, setSearching] = useState(false)
  const [openMarinaLoginModal, setOpenMarinaLoginModal] = useState(false)
  const [openSearchVesselModal, setOpenSearchVesselModal] = useState(false)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const search = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setSearching(true)
    try {
      const movement = await movementService.getByLocator(reservation)
      if (!isEmpty(movement)) {
        navigate(`/customer/${reservation}`)
      } else {
        enqueueSnackbar('Localizador não encontrado', { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(
        (error as { message: string }).message ||
          'Ocorreu um problema, tente novamente',
        {
          variant: 'error'
        }
      )
    } finally {
      setSearching(false)
    }
  }

  const handleCloseModal = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') return
    setOpenMarinaLoginModal(false)
    setOpenSearchVesselModal(false)
  }

  const fillSelectedVessel = (selectedVessel: any) => {
    handleCloseModal('e', 'reason')
    const tempReservation = {
      locator: '',
      status: 'Sem status definido',
      date: dateToString(dateAdd('d', new Date(), 1), 'dd/mm/yyyy'),
      departure: '09:00',
      arrives: '18:00',
      navigationPlan: selectedVessel.navigationPlan,
      passengers: selectedVessel.passengers,
      sailorRequested: selectedVessel.sailorRequested,
      vesselId: selectedVessel.vesselId,
      vesselName: selectedVessel.vesselName,
      vesselEnrollment: selectedVessel.vesselEnrollment,
      vesselModel: selectedVessel.vesselModel,
      vesselType: selectedVessel.vesselType,
      vesselBrand: selectedVessel.vesselBrand,
      vesselImage: selectedVessel.vesselImage,
      vesselIndAlert: selectedVessel.vesselIndAlert,
      vesselIndRestriction: selectedVessel.vesselIndRestriction,
      /*
      ownerId: selectedVessel.ownerId,
      ownerName: selectedVessel.ownerName,
      ownerRegistration: selectedVessel.ownerRegistration,
      */
      ownerCheckIn: selectedVessel.ownerCheckIn,
      partnerId: selectedVessel.partnerId,
      partnerName: selectedVessel.partnerName
    }
    navigate('/customer/schedule', { state: { tempReservation } })
  }

  return (
    <div className={styles.totem}>
      <div className={styles.wrapper}>
        <section className={styles.logo}>
          <img className={styles.logo} src={NautiSystem} alt="" />
        </section>
        <section className={styles.content}>
          <form
            action=""
            className={`${styles.form} mb-5`}
            noValidate
            autoComplete="off"
          >
            <h6>DIGITE SEU LOCALIZADOR</h6>

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="reservation"
              label="Localizador"
              name="reservation"
              type="text"
              autoFocus
              onChange={e => setReservation(e.target.value.toUpperCase())}
              disabled={searching}
              color="primary"
              inputProps={{
                maxLength: 6,
                style: { textTransform: 'uppercase' }
              }}
              className="submit"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={`${styles.submit} mt-4`}
              onClick={search}
              disabled={searching || reservation === ''}
            >
              {searching ? 'AGUARDE...' : 'BUSCAR'}
            </Button>
          </form>
          <section className={styles.totemOptions}>
            <div className="d-flex flex-column align-items-center">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                className={`${styles.submit} mt-4 p-4 mb-2`}
                onClick={() => setOpenSearchVesselModal(true)}
              >
                <EditCalendarIcon sx={{ fontSize: '48px' }} />
              </Button>
              <p>AGENDAMENTO</p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                className={`${styles.submit} mt-4 p-4 mb-2`}
                onClick={() => setOpenMarinaLoginModal(true)}
              >
                <HouseboatIcon sx={{ fontSize: '48px' }} />
              </Button>
              <p>MARINA</p>
            </div>
          </section>
        </section>
      </div>
      <MarinaLoginModal
        open={openMarinaLoginModal}
        handleClose={handleCloseModal}
        height="fit-content"
        width="fit-content"
        onCancel={handleCloseModal}
      />
      <SearchVesselModal
        open={openSearchVesselModal}
        handleClose={handleCloseModal}
        height="fit-content"
        width={430}
        onCancel={handleCloseModal}
        onSelectedVessel={(e: any) => fillSelectedVessel(e)}
        isNewReservation
      />
    </div>
  )
}

export default Totem
