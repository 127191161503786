export const isDate = (d: Date) => {
  if (d.toISOString()) {
    return true
  }
  return false
}

export const getDocumentType = (document: string) => {
  if (document?.length) {
    if (document?.length === 11) {
      return 'CPF'
    }
    if (document?.length === 14) {
      return 'CNPJ'
    }
  }
  return ''
}

export const validPhone = /^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/

export const validEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

export const relationshipOptions = [
  {
    label: 'Cônjuge',
    value: 'spouse'
  },
  {
    label: 'Familiar',
    value: 'parent'
  },
  {
    label: 'Amigo(a)',
    value: 'friend'
  }
]

export const vesselTypeOptions = [
  {
    label: 'Jet Ski',
    value: 'jet',
    id: 33
  },
  {
    label: 'Lancha',
    value: 'boat',
    id: 34
  }
]

export const vesselColorOptions = [
  {
    label: 'Branco',
    value: 'white'
  },
  {
    label: 'Cinza',
    value: 'gray'
  },
  {
    label: 'Branco',
    value: 'white'
  },
  {
    label: 'Azul',
    value: 'blue'
  },
  {
    label: 'Aqua',
    value: 'aqua'
  },
  {
    label: 'Amarelo',
    value: 'yellow'
  },
  {
    label: 'Laranja',
    value: 'orange'
  },
  {
    label: 'Verde',
    value: 'green'
  },
  {
    label: 'Aquamarine',
    value: 'aquamarine'
  },
  {
    label: 'Roxo',
    value: 'purple'
  },
  {
    label: 'Rosa',
    value: 'pink'
  },
  {
    label: 'Marrom',
    value: 'brown'
  },
  {
    label: 'Preto',
    value: 'black'
  },
  {
    label: 'Vermelho',
    value: 'red'
  }
]

export const manufacturerOptions = [
  { label: 'Azimut Yachts', value: 'Azimut Yachts' },
  { label: 'Cabrasmar', value: 'Cabrasmar' },
  { label: 'Cimitarra', value: 'Cimitarra' },
  { label: 'Ferretti', value: 'Ferretti' },
  { label: 'Fibrafort', value: 'Fibrafort' },
  { label: 'Fluvimar', value: 'Fluvimar' },
  { label: 'FS Yachts', value: 'FS Yachts' },
  { label: 'Intermarine', value: 'Intermarine' },
  { label: 'Kawazaki', value: 'Kawazaki' },
  { label: 'Levefort', value: 'Levefort' },
  { label: 'Quest', value: 'Quest' },
  { label: 'Schaefer Yachts', value: 'Schaefer Yachts' },
  { label: 'Seadoo', value: 'Sea-Doo' },
  { label: 'Sessa Marine', value: 'Sessa' },
  { label: 'Yamaha', value: 'Yamaha' },
  { label: 'Outro', value: 'Outro' }
]

export const states = [
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Distrito Federal', value: 'DF' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'Tocantins', value: 'TO' }
]

export const formatPhone = (phone: any) => {
  if (!phone) return ''
  return phone
    ?.replace(/\D/g, '')
    ?.replace(/^(\d{2})(\d)/g, '($1) $2')
    ?.replace(/(\d)(\d{4})$/, '$1-$2')
}

export const formatDocument = (document: string) => {
  if (Number(document)) {
    if (document?.length < 12) {
      return document
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    }
    if (document?.length >= 12)
      return document
        .replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/\.(\d{3})(\d)/, '.$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
  } else {
    return ''
  }

  return document
}
