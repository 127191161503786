import React, { useEffect, useState } from 'react'
import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import Header from '../../../components/header/Header'
import styles from './HourlyOccupation.module.scss'
import Jet from '../../../../assets/images/Jetski.svg'
import { removeFormatDate } from '../../../utils/masks'
import { dateToString } from '../../../utils/auxiliary-functions'
import { authService, movementService } from '../../../services/ms-nts'
import Loader from '../../../components/loader/loader'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function HourlyOccupation() {
  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(false)
  const [disabledHours, setDisabledHours] = useState([] as any)
  const [confirmedReservations, setConfirmedReservations] = useState([] as any)
  const window = 5
  const today = new Date()
  const navigate = useNavigate()
  const selectedDepartureHour = ''

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const parseHour = (hour: string): string => {
    if (hour.length === 1) {
      return `0${hour}`
    }
    return hour
  }

  const handleHour = (hour: string) => {
    if (!disabledHours.includes(hour)) {
      navigate(`/marina/reservation/new/${hour}`)
    } else {
      const { reservation } = confirmedReservations.find(
        (res: any) => res.hour === hour
      )
      navigate(`/marina/reservation/${reservation}`)
    }
  }

  useEffect(() => {
    setLoading(true)
    const dateDefault = removeFormatDate(dateToString(today, 'dd/mm/yyyy'))
    const partner = authService.currentPartner()
    movementService
      .getAppointmentByDate(dateDefault, partner.id)
      .then((result: any) => {
        if (result) {
          const local = result.map((movement: any) =>
            dateToString(movement.departure, 'HH:MM')
          )
          const reservations = result.map((res: any) => ({
            hour: dateToString(res.departure, 'HH:MM'),
            reservation: res.locator,
            vesselColor: res.vesselColor
          }))
          setConfirmedReservations(reservations)
          setDisabledHours(local)
          setLoading(false)
        }
      })
  }, [])

  return (
    <>
      <Header title="Ocupação Horária" />
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.hourlyOccupation}>
          <Box
            sx={{
              width: '100%',
              background: 'rgba(255, 255, 255, 0.055)',
              borderRadius: '4px'
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="fullWidth"
              >
                <Tab label="Manhã (20%)" {...a11yProps(0)} />
                <Tab label="Tarde (0%)" {...a11yProps(1)} />
                <Tab label="Noite (0%)" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <span className={styles.hourWrapper}>
                <span className="d-flex w-100">
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `08:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${
                              disabledHours.includes(
                                `08:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour} ${
                              selectedDepartureHour ===
                              `08:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            }`}
                          >
                            {disabledHours.includes(
                              `08:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  08:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                08:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
                <span className={`d-flex w-100 ${styles.grayLine}`}>
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `09:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${styles.grayLine} ${
                              selectedDepartureHour ===
                              `09:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            } ${
                              disabledHours.includes(
                                `09:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour}`}
                          >
                            {disabledHours.includes(
                              `09:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  09:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                09:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
                <span className="d-flex w-100">
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `10:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${
                              disabledHours.includes(
                                `10:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour} ${
                              selectedDepartureHour ===
                              `10:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            }`}
                          >
                            {disabledHours.includes(
                              `10:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  10:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                10:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
                <span className={`d-flex w-100 ${styles.grayLine}`}>
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `11:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${styles.grayLine} ${
                              disabledHours.includes(
                                `11:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour} ${
                              selectedDepartureHour ===
                              `11:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            }`}
                          >
                            {disabledHours.includes(
                              `11:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  11:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                11:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
                <span className="d-flex w-100">
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `12:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${
                              disabledHours.includes(
                                `12:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour} ${
                              selectedDepartureHour ===
                              `12:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            }`}
                          >
                            {disabledHours.includes(
                              `12:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  12:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                12:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
              </span>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <span className={styles.hourWrapper}>
                <span className="d-flex w-100">
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `13:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${
                              disabledHours.includes(
                                `13:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour} ${
                              selectedDepartureHour ===
                              `13:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            }`}
                          >
                            {disabledHours.includes(
                              `13:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  13:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                13:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
                <span className={`d-flex w-100 ${styles.grayLine}`}>
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `14:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${styles.grayLine} ${
                              selectedDepartureHour ===
                              `14:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            } ${
                              disabledHours.includes(
                                `14:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour}`}
                          >
                            {disabledHours.includes(
                              `14:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  14:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                14:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
                <span className="d-flex w-100">
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `15:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${
                              disabledHours.includes(
                                `15:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour} ${
                              selectedDepartureHour ===
                              `15:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            }`}
                          >
                            {disabledHours.includes(
                              `15:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  15:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                15:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
                <span className={`d-flex w-100 ${styles.grayLine}`}>
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `16:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${styles.grayLine} ${
                              disabledHours.includes(
                                `16:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour} ${
                              selectedDepartureHour ===
                              `16:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            }`}
                          >
                            {disabledHours.includes(
                              `16:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  16:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                16:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
                <span className="d-flex w-100">
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `17:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${
                              disabledHours.includes(
                                `17:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour} ${
                              selectedDepartureHour ===
                              `17:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            }`}
                          >
                            {disabledHours.includes(
                              `17:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  17:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                17:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
              </span>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <span className={styles.hourWrapper}>
                <span className="d-flex w-100">
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `18:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${
                              disabledHours.includes(
                                `18:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour} ${
                              selectedDepartureHour ===
                              `18:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            }`}
                          >
                            {disabledHours.includes(
                              `18:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  18:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                18:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
                <span className="d-flex w-100">
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `19:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${
                              disabledHours.includes(
                                `19:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour} ${
                              selectedDepartureHour ===
                              `19:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            }`}
                          >
                            {disabledHours.includes(
                              `19:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  19:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                19:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
                <span className={`d-flex w-100 ${styles.grayLine}`}>
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `20:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${styles.grayLine} ${
                              selectedDepartureHour ===
                              `20:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            } ${
                              disabledHours.includes(
                                `20:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour}`}
                          >
                            {disabledHours.includes(
                              `20:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  20:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                20:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
                <span className="d-flex w-100">
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `21:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${
                              disabledHours.includes(
                                `21:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour} ${
                              selectedDepartureHour ===
                              `21:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            }`}
                          >
                            {disabledHours.includes(
                              `21:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  21:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                21:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
                <span className={`d-flex w-100 ${styles.grayLine}`}>
                  <span className="d-flex justify-content-between w-100">
                    {Array(60 / window)
                      .fill(0)
                      ?.map(
                        (
                          option: any,
                          index: {
                            toString: () => React.Key | null | undefined
                          }
                        ) => (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleHour(
                                `22:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                            }
                            key={uuid()}
                            className={`${styles.grayLine} ${
                              disabledHours.includes(
                                `22:${parseHour(
                                  (window * Number(index)).toString()
                                )}`
                              )
                                ? styles.unavailable
                                : ''
                            } ${styles.hour} ${
                              selectedDepartureHour ===
                              `22:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                                ? styles.selected
                                : ''
                            }`}
                          >
                            {disabledHours.includes(
                              `22:${parseHour(
                                (window * Number(index)).toString()
                              )}`
                            ) ? (
                              <div
                                className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                              >
                                <span>
                                  {' '}
                                  22:
                                  {parseHour(
                                    (window * Number(index)).toString()
                                  )}
                                </span>
                                <div className="d-flex justify-content-center mt-1">
                                  <img
                                    alt="JetSki"
                                    src={Jet}
                                    className="jet"
                                    style={{
                                      height: 28
                                    }}
                                  />
                                  <small className="ms-1">{`10'`}</small>
                                </div>
                                <span
                                  className={styles.vesselColor}
                                  style={{ backgroundColor: 'yellow' }}
                                />
                              </div>
                            ) : (
                              <span className="d-flex flex-column align-items-center">
                                22:
                                {parseHour((window * Number(index)).toString())}
                                <span className={styles.availableHour}>
                                  Livre
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      )}
                  </span>
                </span>
              </span>
            </TabPanel>
          </Box>
          <div className="d-flex justify-content-between">
            <Button
              variant="outlined"
              color="inherit"
              className={`${styles.logoutButton} mt-3`}
              onClick={() => navigate(-1)}
            >
              Voltar
              <KeyboardArrowLeftIcon />
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={`${styles.logoutButton} mt-3`}
              onClick={() => navigate('/marina/hourly-prediction')}
            >
              PREVISÃO SEMANAL
              <QueryBuilderIcon className="ms-2" />
            </Button>
            <div style={{ width: '100px' }} />
          </div>
        </div>
      )}
    </>
  )
}
