import env from 'react-dotenv'
import { serviceNts } from '../ServiceNts.service'

const { API_SSO, API_APP, DISTRIBUTION } = env

const TIME_EXPIRES = 100
const USER =
  DISTRIBUTION === 'Homologation' ? '@AppNtsHmlTotem:user' : '@AppNtsTotem:user'
const PARTNER =
  DISTRIBUTION === 'Homologation'
    ? '@AppNtsHmlTotem:partner'
    : '@AppNtsTotem:partner'

const login = async (username: string, password: string): Promise<boolean> => {
  try {
    const urlUer = `${API_SSO}/signin`
    const user = await serviceNts.post(urlUer, {
      username,
      password
    })

    if (user && user.situation === 5) {
      setStorage(USER, JSON.stringify(user), TIME_EXPIRES * 5)
      const urlPartner = `${API_APP}/marine/permission_user/${user.id}`
      const partner = await serviceNts.get(urlPartner)
      setStorage(PARTNER, JSON.stringify(partner))
      return true
    }
    return false
  } catch (error: any) {
    throw new Error(error.message)
  }
}

const logout = () => {
  removeStorage(USER)
  removeStorage(PARTNER)
}

const currentUser = () => {
  const local = getStorage(USER)
  if (local) return JSON.parse(local)
  return {}
}

const currentPartner = () => {
  const local = getStorage(PARTNER)
  if (local) return JSON.parse(local)
  return {}
}

const removeStorage = (key: string): boolean => {
  try {
    localStorage.removeItem(key)
    localStorage.removeItem(`${key}_expires`)
  } catch (error: any) {
    return false
  }
  return true
}

const getStorage = (key: string): any => {
  if (key === USER) {
    const now = Date.now()
    // epoch time, lets deal only with integer
    // set expiration for storage
    let expires = localStorage.getItem(`${key}_expires`)
    if (expires === undefined || expires === null) {
      expires = '0'
    }

    if (Number(expires) < now) {
      // Expired
      removeStorage(USER)
      removeStorage(PARTNER)
      return null
    }
    try {
      return localStorage.getItem(key)
    } catch (error: any) {
      return null
    }
  } else {
    try {
      return localStorage.getItem(key)
    } catch (error: any) {
      return null
    }
  }
}

const setStorage = (key: string, value: string, expires?: number): boolean => {
  if (expires === undefined || expires === null) {
    localStorage.setItem(key, value)
  } else {
    expires = Math.abs(expires) // make sure it's positive
    const now = Date.now() // millisecs since epoch time, lets deal only with integer
    const schedule = now + expires * 1000
    try {
      localStorage.setItem(key, value)
      localStorage.setItem(`${key}_expires`, String(schedule))
    } catch (error: any) {
      return false
    }
  }

  return true
}

export const authService = {
  login,
  logout,
  currentUser,
  currentPartner
}
