import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'

import styles from './CheckIn.module.scss'
import CheckInCard from './components/check-in-card/CheckInCard'
import CheckInConfirmationModal from './components/check-in-confirmation-modal/CheckInConfirmationModal'
import { movementService } from '../../services/ms-nts/Movement.service'
import { dateToString } from '../../utils/auxiliary-functions'

const CheckIn: React.FC = () => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [checkinDisabled, setCheckInDisabled] = useState(false)
  const [modalType, setModalType] = useState('')

  const navigate = useNavigate()
  const { reservation = '' } = useParams<'reservation'>()

  const [partnerName, setPartnerName] = useState('')
  const [navegation, setNavegation] = useState('')
  const [departure, setDeparture] = useState('')
  const [forecast, setForecast] = useState('')
  const [move, setMove] = useState(0)
  const [checkIn, setCheckIn] = useState('')
  const [conductor, setConductor] = useState('')
  const [vesselName, setVesselName] = useState('')
  const [vesselType, setVesselType] = useState('')
  const [vesselModel, setVesselModel] = useState('')
  const [vesselYear, setVesselYear] = useState('')
  const [vesselEnrollment, setVesselEnrollment] = useState('')
  const [vesselSize, setVesselSize] = useState('')
  const [vesselColor, setVesselColor] = useState('')
  const [vesselOwners, setVesselOwners] = useState([])
  const [keepBookcaseName, setKeepBookcaseName] = useState('')
  const [keepShelfName, setKeepShelfName] = useState('')
  const [keepDrawerName, setKeepDrawerName] = useState('')

  const handleConfirmationModal = (type: string) => {
    setOpenConfirmationModal(true)
    setModalType(type)
  }

  const handleCloseModal = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') return
    setOpenConfirmationModal(false)
    setModalType('')
  }

  const handleClickCheckIn = (action: string, registration: string) => {
    setCheckIn(action) // 'canceled' | 'confirmed'
    movementService.updateCheckIn(reservation, action, registration)
  }

  const confirm = () => {
    navigate('/')
  }

  useEffect(() => {
    if (reservation !== '') {
      movementService.getByLocator(reservation).then((movement: any) => {
        setPartnerName(movement.partnerName)
        setNavegation(dateToString(movement.navegation, 'dd/mm/yyyy'))
        setDeparture(dateToString(movement.departure, 'HH:MM'))
        setForecast(dateToString(movement.forecast, 'HH:MM'))
        setMove(movement.move)
        setCheckIn(movement.checkIn)
        setConductor(movement.conductor)
        setVesselName(movement.vesselName)
        setVesselType(movement.vesselType)
        setVesselModel(movement.vesselModel)
        setVesselYear(movement.vesselYear)
        setVesselEnrollment(movement.vesselEnrollment)
        setVesselSize(movement.vesselSize)
        setVesselColor(movement.vesselColor)
        setVesselOwners(movement.vesselOwners)
        setKeepBookcaseName(movement.keepBookcaseName)
        setKeepShelfName(movement.keepShelfName)
        setKeepDrawerName(movement.keepDrawerName)

        const today = new Date().toLocaleDateString()
        const reservationDate = new Date(
          new Date(movement.navegation).setDate(
            new Date(movement.navegation).getDate() + 1
          )
        ).toLocaleDateString()

        if (reservationDate !== today) {
          setCheckInDisabled(true)
        }
      })
    }
  }, [reservation])

  return (
    <div className={styles.checkIn}>
      <div className={styles.wrapper}>
        <section className={styles.reservation}>
          <CheckInCard
            navegation={navegation}
            departure={departure}
            forecast={forecast}
            locator={reservation}
            checkIn={checkIn}
            conductor={conductor}
            vesselName={vesselName}
            vesselType={vesselType}
            vesselModel={vesselModel}
            vesselYear={vesselYear}
            vesselEnrollment={vesselEnrollment}
            vesselSize={vesselSize}
            vesselColor={vesselColor}
            keepBookcaseName={keepBookcaseName}
            keepShelfName={keepShelfName}
            keepDrawerName={keepDrawerName}
          />
        </section>
        <section className={styles.checkInControls}>
          <h2 className="text-center mb-0 mt-2 me-5">{partnerName}</h2>
          <div className="d-flex justify-content-center">
            {move === 0 && checkIn === 'confirmed' && (
              <Button
                variant="contained"
                color="success"
                size="large"
                className={styles.checkInButton}
                onClick={() => handleConfirmationModal('requestDeparture')}
              >
                <p>
                  SOLICITAR DESCIDA
                  <KeyboardDoubleArrowDownIcon
                    sx={{ fontSize: 26 }}
                    className="ms-2"
                  />
                </p>
              </Button>
            )}
            {(move === 0 || move === 1) && checkIn === 'confirmed' && (
              <Button
                variant="contained"
                color="error"
                size="large"
                className={styles.cancelButton}
                onClick={() => handleConfirmationModal('cancelCheckin')}
              >
                <p>CANCELAR CHECK-IN</p>
              </Button>
            )}
            {checkIn === 'pending' && (
              <>
                <div>
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    disabled={checkinDisabled}
                    className={`${styles.checkInButton} ${
                      checkinDisabled ? styles.disabled : ''
                    }`}
                    onClick={() => handleConfirmationModal('confirm')}
                  >
                    EFETUAR CHECK-IN
                  </Button>
                  {checkinDisabled && (
                    <p className="mt-2 ms-3">
                      Check-in disponível em {navegation}
                    </p>
                  )}
                </div>
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  className={styles.cancelButton}
                  onClick={() => handleConfirmationModal('cancel')}
                >
                  CANCELAR RESERVA
                </Button>
              </>
            )}
          </div>
          <div className="d-flex justify-content-end w-100">
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={`${styles.logoutButton} mt-4`}
              onClick={() => handleConfirmationModal('logout')}
            >
              SAIR
              <LogoutIcon className="ms-2" />
            </Button>
          </div>
        </section>
      </div>
      <CheckInConfirmationModal
        open={openConfirmationModal}
        handleClose={handleCloseModal}
        height="fit-content"
        width={450}
        reservation={reservation}
        owners={vesselOwners}
        conductor={conductor}
        type={modalType}
        onConfirm={confirm}
        onCancel={handleCloseModal}
        onCancelReservation={(registration: string) =>
          handleClickCheckIn('canceled', registration)
        }
        onLogout={confirm}
        onCheckIn={(registration: string) =>
          handleClickCheckIn('confirmed', registration)
        }
      />
    </div>
  )
}

export default CheckIn
