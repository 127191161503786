import { AxiosResponse } from 'axios'
import env from 'react-dotenv'
import api from '../api/api'

const { API_SSO } = env

const auth = async () => {
  const authorization = {
    'content-type': 'application/json'
  } as any
  const resultAuth = await api.get(`${API_SSO}/authorization`)
  if (resultAuth.status === 202 && resultAuth.data.success) {
    authorization['x-access-token'] = resultAuth.data.object
  }
  return authorization
} // auth

const treatError = (error: any) => {
  const messageError =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString()

  let message = messageError
  if (messageError.indexOf('Controller') > 0) {
    message = messageError.substring(messageError.indexOf('Controller') + 13)
  }
  return message
} // treatError

const getReturnObject = async (url: string) => {
  const headers = await auth()

  return api
    .get(url, headers)
    .then((response: AxiosResponse) => {
      if (response instanceof Error)
        throw new Error(
          (response as { message: string }).message ||
            'Erro ao consultar o registro !!'
        )

      if (response.status >= 200 && response.status < 300) {
        const { data } = response
        if (data.success) {
          return data.object
        }
        throw new Error(data.message)
      }
      throw new Error('Erro ao consultar o registro !!!')
    })
    .catch((error: any) => {
      const responseMessage = treatError(error)
      throw new Error(responseMessage)
    })
} // getReturnObject

const postReturnObject = async (url: string, params: any) => {
  const headers = await auth()

  return api
    .post(url, params, headers)
    .then((response: AxiosResponse) => {
      if (response instanceof Error)
        throw new Error(
          (response as { message: string }).message ||
            'Erro ao incluir o registro !!'
        )

      if (response.status >= 200 && response.status < 300) {
        const { data } = response
        if (data.success) {
          return data.object
        }
        throw new Error(data.message)
      }
      throw new Error('Erro ao incluir o registro !!!')
    })
    .catch((error: any) => {
      const responseMessage = treatError(error)
      throw new Error(responseMessage)
    })
} // postReturnObject

const putReturnObject = async (url: string, params: any) => {
  const headers = await auth()

  return api
    .put(url, params, headers)
    .then((response: AxiosResponse) => {
      if (response instanceof Error)
        throw new Error(
          (response as { message: string }).message ||
            'Erro ao alterar o registro !!'
        )

      if (response.status >= 200 && response.status < 300) {
        const { data } = response
        if (data.success) {
          return data.object
        }
        throw new Error(data.message)
      }
      throw new Error('Erro ao alterar o registro !!!')
    })
    .catch((error: any) => {
      const responseMessage = treatError(error)
      throw new Error(responseMessage)
    })
} // putReturnObject

const deleteReturnObject = async (url: string) => {
  const headers = await auth()

  return api
    .delete(url, headers)
    .then((response: AxiosResponse) => {
      if (response instanceof Error)
        throw new Error(
          (response as { message: string }).message ||
            'Erro ao alterar o registro !!'
        )

      if (response.status >= 200 && response.status < 300) {
        const { data } = response
        if (data.success) {
          return data.object
        }
        throw new Error(data.message)
      }
      throw new Error('Erro ao alterar o registro !!!')
    })
    .catch((error: any) => {
      const responseMessage = treatError(error)
      throw new Error(responseMessage)
    })
} // deleteReturnObject

export const serviceNts = {
  auth,
  get: getReturnObject,
  post: postReturnObject,
  put: putReturnObject,
  delete: deleteReturnObject
}
