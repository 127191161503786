import React, { useState } from 'react'

import { Button, TextField } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import TotemModal from '../../../../components/modal/Modal'
import { authService } from '../../../../services/ms-nts'

type ModalProps = {
  open: boolean
  handleClose: any
  height: any
  onCancel?: any
  width: any
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#3366FF'
    },
    secondary: {
      main: '#4E5F78'
    },
    mode: 'light'
  },

  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  }
})

const MarinaLoginModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  height,
  onCancel,
  width
}: ModalProps) => {
  const [handling, setHandling] = useState(false)
  const [loginForm, setLoginForm] = useState({ user: '', password: '' })
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const cancel = () => {
    onCancel()
    setLoginForm({ user: '', password: '' })
  }

  const login = async () => {
    setHandling(true)

    try {
      const accessGranted = await authService.login(
        loginForm.user,
        loginForm.password
      )
      if (accessGranted) {
        setHandling(false)
        navigate('/marina')
      } else {
        enqueueSnackbar('Usuário e/ou senha incorretos 2', {
          variant: 'error'
        })
      }
    } catch (error: any) {
      enqueueSnackbar(
        error.message || 'Ocorreu um erro generico, tente novamente',
        {
          variant: 'error'
        }
      )
    } finally {
      setHandling(false)
    }
  }

  const handleKeypress = (e: any) => {
    if (e.charCode === 13) {
      login()
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <TotemModal
        open={open}
        name="Login Marina"
        handleClose={handleClose}
        noClose
        width={width}
        height={height}
      >
        <div className="d-flex align-items-center flex-column">
          <TextField
            margin="normal"
            name="user"
            type="text"
            id="document"
            autoFocus
            variant="outlined"
            autoComplete="off"
            onChange={e => setLoginForm({ ...loginForm, user: e.target.value })}
            value={loginForm.user}
            label="Usuário"
            disabled={handling}
            color="primary"
            className="mt-3 mb-0"
            sx={{ minWidth: 325, maxWidth: 325 }}
          />
          <TextField
            margin="normal"
            name="password"
            type="password"
            id="password"
            variant="outlined"
            autoComplete="off"
            onChange={e =>
              setLoginForm({ ...loginForm, password: e.target.value })
            }
            value={loginForm.password}
            label="Senha"
            disabled={handling}
            onKeyPress={handleKeypress}
            color="primary"
            className="mt-3 mb-0"
            sx={{ minWidth: 325, maxWidth: 325 }}
          />
          <div className="mt-4 justify-content-center d-flex">
            <Button
              variant="outlined"
              onClick={() => cancel()}
              color="secondary"
              className="me-4"
              disabled={handling}
              size="large"
            >
              Cancelar
            </Button>
            <Button
              disabled={
                handling || loginForm.user === '' || loginForm.password === ''
              }
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => login()}
            >
              {!handling ? 'LOGIN' : 'Aguarde...'}
            </Button>
          </div>
        </div>
      </TotemModal>
    </ThemeProvider>
  )
}

MarinaLoginModal.defaultProps = {
  onCancel: false
}

export default MarinaLoginModal
