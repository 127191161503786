import env from 'react-dotenv'
import { serviceNts } from '../ServiceNts.service'

const { API_APP } = env

// Enrollment Or IdentifyKey Or Registration
const getByDocument = async (document: string): Promise<any | Error> => {
  try {
    const dinamicUrl = `${API_APP}/vessel/document/${document}`
    const vessels = await serviceNts.get(dinamicUrl)
    return Promise.resolve(vessels)
  } catch (error) {
    return Promise.reject(
      new Error(
        (error as { message: string }).message || 'Erro ao consultar o registro'
      )
    )
  }
}

export const vesselService = {
  getByDocument
}
